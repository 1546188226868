const animationDuration = '500ms';

const SlidingImgAndVideoStyles = (theme) => ({
  '@keyframes slideInFromRight': {
    from: {
      opacity: 0,
      transform: 'translateX(20%)'
    },

    to: {
      opacity: 1,
      transform: 'translateX(0)'
    }
  },

  '@keyframes slideInFromLeft': {
    from: {
      opacity: 0,
      transform: 'translateX(-20%)'
    },

    to: {
      opacity: 1,
      transform: 'translateX(0)'
    }
  },

  '@keyframes slideOutToLeft': {
    from: {
      opacity: 1,
      transform: 'translateX(0)'
    },

    to: {
      opacity: 0,
      transform: 'translateX(-20%)'
    }
  },

  '@keyframes slideOutToRight': {
    from: {
      opacity: 1,
      transform: 'translateX(0)'
    },

    to: {
      opacity: 0,
      transform: 'translateX(20%)'
    }
  },

  '@keyframes slideInFromTop': {
    from: {
      opacity: 0,
      transform: 'translateY(-5%)'
    },

    to: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  },

  '@keyframes slideInFromBottom': {
    from: {
      opacity: 0,
      transform: 'translateY(5%)'
    },

    to: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  },

  '@keyframes slideOutToTop': {
    from: {
      opacity: 1,
      transform: 'translateY(0)'
    },

    to: {
      opacity: 0,
      transform: 'translateY(-5%)'
    }
  },

  '@keyframes slideOutToBottom': {
    from: {
      opacity: 1,
      transform: 'translateY(0)'
    },

    to: {
      opacity: 0,
      transform: 'translateY(5%)'
    }
  },

  slideInFromLeft: {
    animation: '$slideInFromLeft ease-out',
    animationDuration: animationDuration
  },

  slideInFromRight: {
    animation: '$slideInFromRight ease-out',
    animationDuration: animationDuration
  },

  slideOutToLeft: {
    animation: '$slideOutToLeft ease-in',
    animationDuration: animationDuration
  },

  slideOutToRight: {
    animation: '$slideOutToRight ease-in',
    animationDuration: animationDuration
  },

  slideInFromTop: {
    animation: '$slideInFromTop ease-out',
    animationDuration: animationDuration
  },

  slideInFromBottom: {
    animation: '$slideInFromBottom ease-out',
    animationDuration: animationDuration
  },

  slideOutToTop: {
    animation: '$slideOutToTop ease-in',
    animationDuration: animationDuration
  },

  slideOutToBottom: {
    animation: '$slideOutToBottom ease-in',
    animationDuration: animationDuration
  },

  arrowGroup: {
    padding: '0 2rem',
    height: '140px',
    '& svg': {
      fontSize: '2.25rem',
      cursor: 'pointer'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0'
    }
  },

  arrowIconDown: {
    transform: 'rotate(180deg)'
  },

  arrowIconLeft: {
    transform: 'rotate(-90deg)'
  },

  arrowIconRight: {
    transform: 'rotate(90deg)'
  },

  disabled: {
    pointerEvents: 'none',
    '& div': {
      opacity: 0.8,
      backgroundColor: '#ccc'
    }
  },

  category: {
    textAlign: 'center'
  },

  categoryTitle: {
    marginTop: '5px'
  }
});
export default SlidingImgAndVideoStyles;
