import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from "@material-ui/core";
import { TopButton, BottomButton, SummaryCard } from "~/components/ui";
import styles from "./ArticleCardBoxStyles";

class CardBox extends Component {
  render() {
    const { id, carousel, title, slug, summary } = this.props.item.attributes;
    const { classes, handleClick } = this.props;
    return (
      <Card elevation={0} square={true}>
        <CardMedia
          className={classes.media}
          image={carousel.data[0].attributes.url}
        >
          <TopButton onClick={() => handleClick(id)}>{slug}</TopButton>
          <Typography className={classes.title}>{title}</Typography>
        </CardMedia>
        <Box className={classes.moreContainer}>
          <CardContent className={classes.more}>
            <SummaryCard content={summary} />
          </CardContent>
          <BottomButton
            className={classes.bottomButton}
            onClick={() => handleClick(id)}
          >
            READ MORE
          </BottomButton>
        </Box>
      </Card>
    );
  }
}

export default withStyles(styles)(CardBox);
