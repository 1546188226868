import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import * as Yup from 'yup';
import {Formik, Form} from 'formik';
import {
  TextInput,
  GreenButton,
  Select,
  Checkbox,
  MultipleSelect,
  GetValues,
  Button,
} from '../ui/FormikInputFields';
import {Grid} from '@material-ui/core';
import {statusObj} from '../utils/commonFunc';
import svg from '../assets/loadingGreen.svg'
const useStyles = makeStyles((theme) => ({
  class1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    borderRadius: '18px',
    padding: '10px',
    width: '80%',
    boxSizing: 'border-box',
    border: '1px rgb(154, 162, 0) solid',
    '& > p': {
      width: '100%',
      fontFamily: 'Roboto',
      fontSize: '1rem',
      textAlign: 'center',
      padding: 10,
    },
  },
}));

const FormBuilder = (props) => {
  const {structure, getValues} = props;
  const classes = useStyles();
  const [status, setStatus] = useState(null);
  return (
    <Grid item xs>
      <Formik
        initialValues={structure.reduce((acc, cv) => {
          if (cv.initialValue === undefined) {
            return acc;
          }
          return {...acc, [cv.inputFieldProps.key]: cv.initialValue};
        }, {})}
        validationSchema={Yup.object(
          structure.reduce((acc, cv) => {
            if (cv.validationSchema === undefined) {
              return acc;
            }
            return {...acc, [cv.inputFieldProps.key]: cv.validationSchema};
          }, {})
        )}
        onSubmit={(values) => {
          setStatus(statusObj('loading'));
          props
            .onSubmit(values)
            .then((res) => {
              if (res.error) {
                setStatus(statusObj('error', res.error));
              }else if(res.info){
                setStatus(statusObj('info', res.info))
              } else {
                setStatus(null);
              }
            })
            .catch((error) => setStatus(statusObj('error', error)));
        }}
      >
        <Form style={{position: 'relative', width: '100%'}}>
          <Grid container style={{width: 'auto', margin: 0}} {...props} structure={null}>
            {structure.map((element, index) => {
              if (element.inputFieldProps.type === 'text') {
                return (
                  <Grid key={index} item {...element.gridItemProps}>
                    <TextInput {...element.inputFieldProps} name={element.inputFieldProps.key} />
                  </Grid>
                );
              } else if (element.inputFieldProps.type === 'password') {
                return (
                  <Grid key={index} item {...element.gridItemProps}>
                    <TextInput {...element.inputFieldProps} type="password" name={element.inputFieldProps.key} />
                  </Grid>
                );
              } else if (element.inputFieldProps.type === 'select') {
                return (
                  <Grid key={index} item {...element.gridItemProps}>
                    <Select {...element.inputFieldProps} name={element.inputFieldProps.key} options={element.options} />
                  </Grid>
                );
              } else if (element.inputFieldProps.type === 'multipleSelect') {
                return (
                  <Grid key={index} item {...element.gridItemProps}>
                    <MultipleSelect
                      {...element.inputFieldProps}
                      type={null}
                      name={element.inputFieldProps.key}
                      options={element.options}
                    />
                  </Grid>
                );
              } else if (element.inputFieldProps.type === 'checkbox') {
                return (
                  <Grid key={index} item {...element.gridItemProps}>
                    <Checkbox
                      inputFieldProps={element.inputFieldProps}
                      ModalComponent={element.ModalComponent}
                      name={element.inputFieldProps.key}
                    />
                  </Grid>
                );
              } else if (element.inputFieldProps.type === 'submit') {
                return (
                  <Grid key={index} item {...element.gridItemProps}>
                    <GreenButton {...element.inputFieldProps}>{element.inputFieldProps.label}</GreenButton>
                  </Grid>
                );
              } else if (element.inputFieldProps.type === 'button') {
                return (
                  <Grid key={index} item {...element.gridItemProps}>
                    <Button inputFieldProps={element.inputFieldProps} />
                  </Grid>
                );
              }
            })}
          </Grid>
          {!!status && (
            <div
              style={{
                background: 'rgba(255, 255, 255, 0.70)',
                top: 0,
                width: '100%',
                height: '100%',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {status.status == 'loading' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'white',
                    borderRadius: '18px',
                    width: '30%',
                    aspectRatio: '1',
                    border: '1px rgb(154, 162, 0) solid',
                  }}
                >
                  <object type="image/svg+xml" data={svg}>svg-animation</object>
                </div>
              ) : null}

              {status.status == 'info' ? (
                <div
                  className={classes.class1}
                  style={{
                    color: 'rgb(154, 162, 0)',
                  }}
                >
                  <p>{status.message}</p>
                  {console.log('within status info',status.message)}
                  <Link to="/visionarie-profile" />
                  <GreenButton onClick={() => setStatus(null)}>Ok</GreenButton>
                </div>
              ) : null}

              {status.status == 'error' ? (
                <div
                  className={classes.class1}
                  style={{
                    color: 'red',
                  }}
                >
                  <p>Error : {status.message}</p>
                  {console.log('within status error',status.message)}
                  <GreenButton onClick={() => setStatus(null)}>Ok</GreenButton>
                </div>
              ) : null}
            </div>
          )}
          <GetValues getValues={getValues} />
        </Form>
      </Formik>
    </Grid>
  );
};

export default FormBuilder;
