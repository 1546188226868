module.exports = {
  app_title: 'Higher Consciousness Network',
  second_genome: 'Second Genome',
  sign_up: 'SIGN UP',
  sign_in: 'SIGN IN',
  site_name: 'Higher Consciousness Network',

  // UI Components
  expand_bar_expand_label: 'Expand',
  component_instagram_gallery_title: 'INSTAGRAM',

  header_introl: 'INTRO',
  header_content: 'CONTENT',
  header_organize: 'ORGANIZE',
  header_network: 'NETWORK',
  header_introl_sub: 'DISCOVER',
  header_content_sub: 'SHIFT',
  header_organize_sub: 'EVOLVE',
  header_network_sub: 'CREATE',
  header_title_left: 'Second',
  header_title_right: 'Genome',

  header_introl_menu_home: 'Home',
  header_introl_menu_cc: 'Creation Compass',
  header_introl_menu_charter: 'Charter',
  header_introl_menu_meta: 'Metamorphosis',
  header_introl_menu_metawheel: 'Metawheel',
  header_content_menu_quotes: 'Quotes & Affirmations',
  header_content_menu_articles: 'Articles',
  header_content_menu_videos: 'Videos & Visualizations',
  header_content_menu_sanctuary: 'Initiatives',
  header_content_menu_guides: 'Guides',
  header_org_menu_health: 'Health',
  header_org_menu_prosperity: 'Prosperity',
  header_org_menu_inspairation: 'Inspiration',
  header_org_menu_joy: 'Joy',
  header_network_menu_contribute: 'Contribute',
  header_network_menu_sponsor: 'Sponsor',
  header_network_menu_disperse: 'Disperse',
  header_network_menu_profile: 'Profile',

  auth_sign_up_form_name_label: 'Name',
  auth_sign_up_form_name_title: 'This username will be permanent',
  auth_sign_up_form_name_placeholder: 'Enter Name',
  auth_sign_up_form_phone_label: 'Phone',
  auth_sign_up_form_phone_title: 'Your phone number. Used for verification and account recovery',
  auth_sign_up_form_phone_placeholder: 'Example: +642255005500',
  auth_sign_up_form_email_label: 'Email',
  auth_sign_up_form_email_title: 'Enter a valid email. This email will be used for account verification and recovery',
  auth_sign_up_form_email_placeholder: 'error@mail.com',
  auth_sign_up_form_password_label: 'Password',
  auth_sign_up_form_password_title: 'Minimum length of 12, no spaces, at least one each of a-z, A-Z, 0-9, and a symbol',
  auth_sign_up_form_password_placeholder: 'Enter Password',
  auth_sign_up_form_confirmed_password_label: 'Confirm Password',
  auth_sign_up_form_confirmed_password_title: 'Minimum length of 12, no spaces, at least one each of a-z, A-Z, 0-9, and a symbol',
  auth_sign_up_form_confirmed_password_placeholder: 'Confirm Password',
  auth_verify_existing_account: 'I want to verify an existing account',
  auth_sign_up_form_donate_box: 'Donate $1.00 to the Inter-Generational Equity Fund',
  auth_sign_up_form_permission_box: 'Permission to please also sign me up to the Sanctuary Apps and Services',
  auth_sign_up_form_button: 'SIGN UP',
  auth_sign_up_form_error_invalid_password:
    'Password must have a minimum length of {MIN_PASSWORD_LENGTH} characters, no spaces, and at least one uppercase char, one lowercase char, one numeral and one symbol',
  auth_sign_up_form_error_invalid_phone_number:
    'Contact Number has an invalid format. Valid examples include +64444555 and +6155556666',
  auth_sign_up_form_error_invalid_email_address: 'Email address has an invalid format',
  auth_sign_up_form_text_heading: 'become a vfh global citizen',
  auth_sign_up_form_text_content: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
  rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt
  explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
  consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
  dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora
  incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
  exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem
  vel eum`,
  auth_sign_up_form_text_privacy: `<strong>Data Privacy Statement</strong>: In being a part of HCN your data is kept private and
  confidential, being used only for the purpose you signed up for.`,
  auth_verification_code_message:
    'An verification code has been sent to the email you provided. Please enter the code below.',
  auth_verify_form_name_label: 'Name',
  auth_verify_form_name_title: 'This username must match the one you signed up with',
  auth_verify_form_name_placeholder: 'Enter Name',
  auth_verify_form_code_label: 'Verification Code',
  auth_verify_form_code_title: 'The Verification code in the email you received',
  auth_verify_form_code_placeholder: 'Enter Code',
  auth_verify_form_button: 'VERIFY',
  auth_verify_form_error_code_mismatch:
    'The validation code provided was not correct. Please enter the correct code and try again',
  auth_verify_form_error_limit_exceeded: 'Verification attempt limit exceeded. Please wait a few minutes and try again',
  auth_verify_form_error_confirmed: 'This user has already been confirmed. Have you entered the correct username?',
  auth_verify_form_error_user_not_found: 'This user was not recognized. Have you entered the correct username?',
  auth_signup_sidebar_health_heading: 'HEALTH',
  auth_signup_sidebar_health_text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud`,
  auth_signup_sidebar_prosperity_heading: 'PROSPERITY',
  auth_signup_sidebar_prosperity_text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud`,
  auth_signup_sidebar_inspiration_heading: 'INSPIRATION',
  auth_signup_sidebar_inspiration_text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud`,
  auth_signup_sidebar_joy_heading: 'JOY',
  auth_signup_sidebar_joy_text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud`,
  auth_sign_in_email_label: 'Email',
  auth_sign_in_email_title: 'Please enter your username here.',
  auth_sign_in_email_placeholder: 'Enter Email',
  auth_sign_in_password_label: 'Password',
  auth_sign_in_password_title:
    'Must have lower case, upper case, numbers and special chars, and a minimum length of 12.',
  auth_sign_in_password_placeholder: 'Enter Password',
  auth_sign_in_check_box_label: 'Remember Me',
  auth_sign_in_forgot_password: 'Forgot Password',
  auth_sign_in_login_button: 'LOGIN',
  auth_sign_in_error_user_not_found: 'Incorrect username or password.',
  auth_sign_in_error_user_password_mismatch: 'Incorrect username or password.',
  auth_sign_in_error_user_disabled:
    'The user has been disabled. Please contact the administrator if you believe this is in error.',
  auth_error_bar_error_label: 'Error:',
  auth_api_error_100100001: 'The user is unauthorized.', // auth.unauthorized
  auth_api_error_100100002: 'These credentials do not match our records.', // auth.failed
  auth_api_error_100100101: 'The email field is required.', // auth.email.required
  auth_api_error_100100102: 'The email has already been taken.', // auth.email.unique
  auth_api_error_100100103: 'The email must be a valid email address.', // auth.email.email
  auth_api_error_100100104: 'The email must be a string.', // auth.email.string
  auth_api_error_100100105: 'The email may not be greater than 255 characters.', // auth.email.max
  auth_api_error_100100201: 'The password field is required.', // auth.password.required
  auth_api_error_100100202: 'The password must be a string.', // auth.password.string
  auth_api_error_100100203: 'The password must be at least 8 characters.', // auth.password.min
  auth_api_error_100100204: 'The password confirmation does not match.', // auth.password.confirmed
  auth_api_error_100100301: 'The from must be a string.', // auth.from.string
  auth_api_error_100100302: 'The from may not be greater than 8 characters.', // auth.from.max

  organize_questionbox_qa: 'Q & A',
  organize_questionbox_viewmore_button: 'VIEW MORE',

  organize_articlebox_title: 'Articles',

  organize_guidebox_title: 'GUIDE',
  organize_guidebox_takeme_button: 'TAKE ME THERE',

  organize_initiativesbox_title: 'INITIATIVES',
  organize_initiativesbox_vision: 'VISION',
  organize_initiativesbox_mission: 'MISSION',
  organize_initiativesbox_character: 'CHARACTER',
  organize_initiativesbox_creation: 'CREATION COMPASS',
  organize_initiativesbox_metawheel: 'METAWHEEL',
  organize_initiativesbox_initiatives: 'INITIATIVES',

  //Organize Section content
  organize_sectioncontent_share: 'SHARE OUR VIDEOS',
  organize_sectioncontent_visit: 'VISIT OUR WORKSHOPS',
  organize_sectioncontent_go_button: 'Go Now',

  //Organize Health Page
  organize_healthpage_title: 'Health',
  organize_healthpage_subtitle_1: 'Vitality and Longevity',
  organize_healthpage_subtitle_2: 'Bio-interdependence',

  //Organize Prosperity Page
  organize_prosperitypage_title: 'Prosperity',
  organize_prosperitypage_subtitle_1: 'Intergenerational Equity',
  organize_prosperitypage_subtitle_2: 'Wisdom and Success',

  //Organize Joy Page
  organize_joypage_title: 'Joy',
  organize_joypage_subtitle_1: 'Spiritual Authenticity',
  organize_joypage_subtitle_2: 'Clarity and Beauty',

  //Organize Inspiration Page
  organize_inspirationpage_title: 'Inspiration',
  organize_inspirationpage_subtitle_1: 'Unification and Belonging',
  organize_inspirationpage_subtitle_2: 'Empowerment and Significance',

  //Network Contribute Page
  contribute_content_heading: 'CONTRIBUTE CONTENT',
  contribute_content_intro_text:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint ipsum quae inventore officiis exceptur officia, minus explicabo vitae est nostrum totam vero nam fugiat saepe cupiditate adipisci maiores voluptatibus! Autem.',

  contribute_content_type: 'CONTENT TYPE',
  contribute_content_type_personal: 'Personal',
  contribute_content_type_global: 'Global',
  contribute_content_type_popover_text_personal:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, laboriosam.',
  contribute_content_type_popover_text_global:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, laboriosam.',

  contribute_content_type_title_quote: 'QUOTE',
  contribute_content_type_title_articles: 'ARTICLES',
  contribute_content_type_title_videos: 'VIDEOS',
  contribute_content_type_title_message: 'MESSAGE',

  contribute_upload_content_note_heading: 'UPLOAD CONTENT',
  contribute_upload_content_note_text_1: 'Quotes - Not more than 2 sentences',
  contribute_upload_content_note_text_2: 'Aritcle - Min 300 words - Max 400 words',

  contribute_upload_input_heading_text: 'UPLOAD TEXT',
  contribute_upload_input_heading_image: 'UPLOAD IMAGE',
  contribute_upload_input_heading_video: 'UPLOAD VIDEO',
  contribute_upload_input_hint_text: 'Doc, PDF, Max file size 1MB',
  contribute_upload_input_hint_image: 'JPEG, JPG, PNG, Max file size 1MB',
  contribute_upload_input_hint_video: 'MP4, MPEG, AVI, Max file size 1MB',

  contribute_term_modal_link: 'READ TERMS & CONDITIONS',
  contribute_term_modal_heading: 'TERMS AND CONDITIONS',
  contribute_terms_heading_1: 'CONSCIOUSNESS',
  contribute_terms_heading_2: 'CATEGORIES',
  contribute_terms_text:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia illo nisi praesentium voluptates veritatis voluptatem aspernatur, quisquam odit officia? Iure, explicabo nostrum. Ipsa laboriosam incidunt rem totam corporis, dolores itaque consequuntur ratione amet et accusantium natus nostrum veniam temporibus soluta',
  contribute_term_modal_checkbox_label: 'I accept terms and conditions',

  contribute_form_button_upload: 'Upload',
  contribute_form_button_preview: 'PREVIEW',
  contribute_form_button_submit: 'SUBMIT',

  //Disperse Page
  header_network_menu_disperse_label: 'Disperse content',
  header_network_menu_disperse_description: 'Become a HCN content disperser',

  //Creation Compass Page
  header_intro_compass_page_title: 'Creation Compass',

  //Additional Action
  article_share_label: 'Share',
  article_comment_label: 'Comment',
  back_to_all: 'Back to all',
  terms_and_conditions_accepted: 'Accepted',
  terms_and_conditions_not_accepted: 'Please read and accept',

  //Intro meta
  intro_meta_title: 'METAMORPHOSIS',
  intro_meta_subtitle: 'The story of Metamorphosis',
  intro_meta_desc_title: 'Metamorphosis',
  intro_meta_desc_content:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tempus enim eu eros molestie, eget porta tellus fermentum. Pellentes in ultricies',
  intro_meta_quote: 'Quote',
  intro_meta_quote_content:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci elit, tincidunt quis ligula a, semper molestie tortor. Ut ut libero ullamcorper, tincidunt risus nec, dapibus felis. Morbi tortor dolor, posuere placerat sapien nec, condimentum varius leo. Pellentesque at fringilla risus, ut ultricies sapien. Nunc rhoncus nunc non purus congue, et dictum risus pulvinar. Donec non ante quam. Pellentesque bibendum, turpis et laoreet mattis, quam libero volutpat augue, a consequat diam nulla eget nisi. Nunc rhoncus nunc non purus congue, et dictum risus pulvinar. Donec non ante quam. Pellentesque bibendum, turpis et laoreet mattis, quam libero volutpat augue, a consequat diam nulla eget nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci elit, tincidunt quis ligula a, semper molestie tortor. Ut ut libero ullamcorper, tincidunt risus nec, dapibus felis. Morbi tortor dolor, posuere placerat sapien nec, condimentum varius leo. Pellentesque at fringilla risus, ut ultricies sapien. ',
  intro_meta_article_title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  intro_meta_article_content:
    'Quisque tempus enim eu eros molestie, eget porta tellus fermentum. Pellentes in ultricies tortor, ac fringilla libero. ',
  intro_meta_human_advance_title: 'Human Advance Architecture',
  intro_meta_human_advance_content:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci elit, tincidunt quis ligula a, semper molestie tortor. Ut ut libero ullamcorper, tincidunt risus nec, dapibus felis. Morbi tortor dolor, posuere placerat sapien nec, condimentum varius leo. Pellentesque at fringilla risus, ut ultricies sapien. Nunc rhoncus nunc non purus congue, et dictum risus pulvinar. Donec non ante quam. Pellentesque bibendum, turpis et laoreet mattis, quam libero volutpat augue, a consequat diam nulla eget nisi. Nunc rhoncus nunc non purus congue, et dictum risus pulvinar. Donec non ante quam. Pellentesque bibendum, turpis et laoreet mattis, quam libero volutpat augue, a consequat diam nulla eget nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci elit, tincidunt quis ligula a, semper molestie tortor. Ut ut libero ullamcorper, tincidunt risus nec, dapibus felis. Morbi tortor dolor, posuere placerat sapien nec, condimentum varius leo. Pellentesque at fringilla risus, ut ultricies sapien.',
  intro_meta_signup: 'SIGN UP',

  //Intro Charter Page
  intro_charter_title: 'Charter',
  intro_charter_switch_label: 'Show me the Truth',

  //Network/Sponsor Page
  sponsor_title: 'Sponsor Content',
  sponsor_subtitle: 'Become a HCN Content Sponsor',
  sponsor_criteria: 'Criteria',
  sponsor_description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci elit, tincidunt quis ligula a, semper molestie tortor. Ut ut libero ullamcorper, tincidunt risus nec, dapibus felis. Morbi tortor dolor, posuere placerat sapien nec, condimentum varius leo.',
  sponsor_name: 'Name',
  sponsor_email: 'Emai',
  sponsor_phone: 'Phone',
  sponsor_organization: 'Organization',
  sponsor_website: 'Website',
  sponsor_address: 'Address',
  sponsor_donation_guidelines: 'Donation Guidelines',
  sponsor_read_terms: 'Read Terms & Conditions',
  sponsor_donation_type: 'Donation Type',
  sponsor_d: 'd',
  sponsor_w: 'w',
  sponsor_m: 'm',

  //Content/Article Detail Page
  article_detail_add_comment_title: 'ADD A COMMENT/MESSAGE',
  article_detail_add_comment_form_name: 'Name',
  article_detail_add_comment_form_email: 'E-mail',
  article_detail_add_comment_form_message: 'Message',
  article_detail_add_comment_form_button_submit: 'SUBMIT',

  article_detail_reader_comments_title: 'READER COMMENTS',

  article_detail_toolbar_action_share: 'SHARE',

  article_detail_shortcut_action_add_to_treasure_box: 'ADD TO TREASURE BOX',
  article_detail_shortcut_action_vitality_longevity: 'Vitality & Longevity',
  article_detail_shortcut_action_print: 'PRINT',

  article_detail_latest_posts_title: 'LATEST POSTS',
  article_detail_latest_posts_filter_choose_category_label: 'Choose your Category',
  article_detail_latest_posts_filter_category_all: 'ALL',

  article_detail_about_author_title: 'ABOUT AUTHOR',
  article_detail_about_author_button_message: 'MESSAGE',
  article_detail_about_author_button_book: 'BOOK',
};
