import React from 'react';
import {withStyles, Paper, Box, Grid, Typography, Slide, Zoom} from '@material-ui/core';
import AnimatedSwitch from '~/components/ui/AnimatedSwitch/AnimatedSwitch';

import styles from './CreationCompassSwitcherStyles';

function CreationCompassSwitcher(props) {
  const {classes} = props;
  const {txt, title, img} = props; //each element is an array (ex. txt[0] and txt[1] etc)
  const {ico, position, switchIndex} = props;
  const [checked, setChecked] = React.useState(false);
  const [slideChecked, setSlideChecked] = React.useState(true);
  const [showInfo, setInfo] = React.useState({txt: txt[0], title: title[0], image: img[0]});

  const toggleChecked = () => {
    var checkedNum = Number(!checked); //get number value 0 or 1 from boolean checked
    setSlideChecked(false);
    const timer = setTimeout(() => {
      setSlideChecked(true);
      setInfo({txt: txt[checkedNum], title: title[checkedNum], image: img[checkedNum]});
    }, 500);
    setChecked((prev) => !prev);

    return () => clearTimeout(timer);
  };

  return (
    <React.Fragment>
      <Paper className={checked ? classes.longPaper : classes.shortPaper} elevation={3}>
        <Box component="div" m={2}>
          <Grid container direction={`row${position}`} justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
              <img src={ico} width="80px" alt={showInfo.title} />
            </Grid>
            <Zoom in={slideChecked} {...(slideChecked ? {timeout: 1000} : {})}>
              <Grid item>
                <img src={showInfo.image} width="100px" alt={showInfo.title} />
              </Grid>
            </Zoom>
            <Grid item>
              <AnimatedSwitch switchIndex={switchIndex} toggleCheck={toggleChecked} />
            </Grid>
          </Grid>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Slide direction="up" in={slideChecked} {...(slideChecked ? {timeout: 1000} : {})}>
              <>
              <Grid item>
                <Typography variant="h5" gutterBottom>
                  {showInfo.title}
                </Typography>
              </Grid>
              </>
            </Slide>
            <Slide direction="up" in={slideChecked} {...(slideChecked ? {timeout: 1000} : {})}>
              <>
              <Grid item>
                <Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{__html: showInfo.txt}} />
              </Grid>
              </>
            </Slide>
          </Grid>
        </Box>
      </Paper>
    </React.Fragment>
  );
}

export default withStyles(styles)(CreationCompassSwitcher);
