import React, {Component} from 'react';
import {withStyles, Grid} from '@material-ui/core';
import {withRouter, Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';

import AnimatedArrow from '~/components/ui/AnimatedArrow/AnimatedArrow';
import CubeIcon from '~/components/ui/CubeIcon/CubeIcon';

import AnimatedArrowGroupStyles from './AnimatedArrowGroupStyles';

const animationDelay = '500';

class AnimatedArrowGroup extends Component {
  state = {
    disabledUpArrow: false,
    disabledDownArrow: false,
    // disabledLeftArrow: false,
    // disabledRightArrow: false,

    filteredItemIndex: null,
    maxFilteredItemsIndex: null,
    categoryCode: this.props.categoryCode,
    categoryIndex: this.props.categoryIndex
  };

  // disableLeftRightArrow = (index) => {
  //   if (index === 0) {
  //     this.setState({
  //       disabledLeftArrow: true
  //     });
  //   } else if (index === this.props.categories.length - 1) {
  //     this.setState({
  //       disabledRightArrow: true
  //     });
  //   } else {
  //     this.setState({
  //       disabledLeftArrow: false,
  //       disabledRightArrow: false
  //     });
  //   }
  // };

  disableUpDownArrow = (index, max) => {
    if (index === 0) {
      this.setState({
        disabledUpArrow: true
      });
    } else if (index === max) {
      this.setState({
        disabledDownArrow: true
      });
    } else {
      this.setState({
        disabledUpArrow: false,
        disabledDownArrow: false
      });
    }
  };

  componentDidMount() {
    const filteredItemIndex = this.props.items.findIndex((item) => item.id === this.props.itemId);
    const maxFilteredItemsIndex = this.props.items.length - 1;
    this.disableUpDownArrow(filteredItemIndex, maxFilteredItemsIndex);
    // this.disableLeftRightArrow(this.state.categoryIndex);
  }

  changeCategory = (index) => {
    const newCategory = this.props.categories.find((cate, idx) => idx === index);

    this.props.handleGetQuotesByCategory(newCategory.code);

    const firstItemId = this.props.items[0].id;
    this.props.updateGlobalItemId(firstItemId);

    this.setState({
      // itemId: firstItemId,
      categoryCode: newCategory.code,
      disabledUpArrow: true,
      disabledDownArrow: false
    });
  };

  goToPrevCategory = () => {
    this.props.toggleSlide('slideOutToRight');
    const categoryIndex = this.props.categories.findIndex((cate) => cate.code === this.state.categoryCode);
    let prevCategoryIndex;
    if (categoryIndex === 0) {
      prevCategoryIndex = this.props.categories.length - 1;
    } else {
      prevCategoryIndex = categoryIndex - 1;
    }

    // this.disableLeftRightArrow(prevCategoryIndex);
    this.changeCategory(prevCategoryIndex);

    setTimeout(() => {
      this.setState({
        categoryIndex: prevCategoryIndex
      });
      this.props.toggleSlide('slideInFromLeft');
    }, animationDelay);
  };

  goToNextCategory = () => {
    this.props.toggleSlide('slideOutToLeft');
    const categoryIndex = this.props.categories.findIndex((cate) => cate.code === this.state.categoryCode);
    let nextCategoryIndex;
    if (categoryIndex === this.props.categories.length - 1) {
      nextCategoryIndex = 0;
    } else {
      nextCategoryIndex = categoryIndex + 1;
    }
    // this.disableLeftRightArrow(nextCategoryIndex);

    setTimeout(() => {
      this.setState({
        categoryIndex: nextCategoryIndex
      });
      this.changeCategory(nextCategoryIndex);
      this.props.toggleSlide('slideInFromRight');
    }, animationDelay);
  };

  updateItemId = (index) => {
    this.disableUpDownArrow(index, this.props.items.length - 1);
    const itemId = this.props.items[index].id;
    this.props.updateGlobalItemId(itemId);
  };

  goToPrevItem = () => {
    this.props.toggleSlide('slideOutToBottom');
    const filteredItemIndex = this.props.items.findIndex((item) => item.id === this.props.itemId);

    this.setState({
      filteredItemIndex: this.state.filteredItemIndex - 1
    });
    setTimeout(() => {
      let prevItemIndex = filteredItemIndex - 1;
      this.updateItemId(prevItemIndex);
      this.props.toggleSlide('slideInFromTop');
    }, animationDelay);
  };

  goToNextItem = () => {
    this.props.toggleSlide('slideOutToTop');
    const filteredItemIndex = this.props.items.findIndex((item) => item.id === this.props.itemId);

    this.setState({
      filteredItemIndex: this.state.filteredItemIndex + 1
    });

    setTimeout(() => {
      let nextItemIndex = filteredItemIndex + 1;
      this.updateItemId(nextItemIndex);
      this.props.toggleSlide('slideInFromBottom');
    }, animationDelay);
  };

  render() {
    const {classes, categories, categoryIndex} = this.props;
    const categoryTitles = categories.map((item) => item.title);

    return (
      <Grid
        item
        container
        className={clsx({
          [classes.arrowGroup]: true,
          [classes.slideInFromLeft]: this.props.slideInFromLeft,
          [classes.slideInFromRight]: this.props.slideInFromRight,
          [classes.slideOutToLeft]: this.props.slideOutToLeft,
          [classes.slideOutToRight]: this.props.slideOutToRight
        })}
        direction="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <AnimatedArrow
          extraClass={clsx({
            [classes.disabled]: this.state.disabledUpArrow
          })}
          handleClick={this.goToPrevItem}
        />
        <Grid item container justifyContent="space-between" alignItems="center">
          <AnimatedArrow
            extraClass={clsx({
              [classes.arrowIconLeft]: true
              // [classes.disabled]: this.state.disabledLeftArrow
            })}
            handleClick={this.goToPrevCategory}
          />
          <Grid item className={classes.category}>
            <RouterLink to="#">
              <CubeIcon categoryIndex={categoryIndex} />
            </RouterLink>
            <div className={classes.categoryTitle}>{categoryTitles[categoryIndex]}</div>
          </Grid>
          <AnimatedArrow
            extraClass={clsx({
              [classes.arrowIconRight]: true
              // [classes.disabled]: this.state.disabledRightArrow
            })}
            handleClick={this.goToNextCategory}
          />
        </Grid>
        <AnimatedArrow
          extraClass={clsx({
            [classes.arrowIconDown]: true,
            [classes.disabled]: this.state.disabledDownArrow
          })}
          handleClick={this.goToNextItem}
        />
      </Grid>
    );
  }
}

export default withRouter(withStyles(AnimatedArrowGroupStyles)(AnimatedArrowGroup));
