import React from "react";
import { FlowGrid } from "~/components/ui";
import CardBox from "~/pages/content/articles/Sections/ArticleCardBox";

export const FormArticleData = ({ goDetail, dataArr }) => {
  console.log("within FormArticleData", dataArr);
  if (dataArr.length > 0) {
    return (
      <div style={{ flexDirection: "column", display: "flex", gap: 4 }}>
        <FlowGrid data={dataArr} cardHeight="200%">
          {(v) => {
            let card = null;
            if (v.id) {
              card = <CardBox item={v} handleClick={goDetail} />;
            }
            return card;
          }}
        </FlowGrid>
      </div>
    );
  } else {
    return <></>;
  }
};
