import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { ExpandBar } from "~/components/ui";
import styles from "./ArticlesMainStyles";
import MyFeed from "~/components/layout/MyFeed/index";
import ToggleContentsTitles from "~/components/ui/ToggleContentTitles";

const useStyles = makeStyles(styles);

const Articles = (props) => {
  const classes = useStyles(props);
  const {
    data,
    mode,
    handleSwitch,
    titles,
    selectedTitleIds,
    onTitlesChange,
  } = props;

  return (
    <Container className={classes.root}>
      <ExpandBar
        nav="Articles"
        subNav={mode}
        goExpanded={props.goExpanded}
        switcher={{
          items: ["Person", "Planet"],
          checkedIndex: mode === "planet" ? 1 : 0,
          onChange: handleSwitch,
        }}
      />
      <ToggleContentsTitles
        titles={titles}
        selectedIds={selectedTitleIds}
        onChange={onTitlesChange}
      />
      <MyFeed data={data} mode={mode} />
    </Container>
  );
};

export default Articles;
