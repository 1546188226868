import React, {Component} from 'react';
import {withRouter, Link as RouterLink} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {withStyles, Grid, Divider} from '@material-ui/core';

import SlidingImgAndVideo from '~/components/ui/SlidingImgAndVideo/SlidingImgAndVideo';
import AnimatedArrowGroup from '~/components/ui/AnimatedArrowGroup/AnimatedArrowGroup';
import SlidingMediaContent from '~/components/ui/SlidingMediaContent/SlidingMediaContent';

import ButterflyIcon from '~/assets/images/icons/share_btn.png';

import VideoData from '~/data/cardVideoData';

import VideoDetailStyle from './VideoDetailStyle';

class VideoDetail extends Component {
  state = {
    slideInFromLeft: false,
    slideInFromRight: false,
    slideOutToLeft: false,
    slideOutToRight: false,
    slideInFromTop: false,
    slideInFromBottom: false,
    slideOutToTop: false,
    slideOutToBottom: false,

    videoId: this.props.videoId
  };

  toggleSlide = (key) => {
    this.setState({
      [key]: true
    });
    setTimeout(() => {
      this.setState({
        [key]: false
      });
    }, 500);
  };

  updateGlobalVideoId = (id) => {
    this.setState({
      videoId: id
    });
  };

  render() {
    const {classes} = this.props;
    const videoIndex = VideoData.findIndex((video) => video.id === this.state.videoId);

    const detailInfo = VideoData[videoIndex].detailInfo.map((item) => (
      <section key={item.id}>
        <Typography component="h4" className={classes.detailInfoTitle}>
          {item.title}
        </Typography>
        <Typography component="p" className={classes.detailInfoDescription}>
          {item.description}
        </Typography>
      </section>
    ));

    const socialMediaImg = VideoData[videoIndex].socialImageURLs.map((img) => (
      <img src={img.url} key={img.id} alt={img.id} />
    ));

    return (
      <div className={classes.container}>
        <Grid container alignItems="center">
          <SlidingImgAndVideo
            slideInFromLeft={this.state.slideInFromLeft}
            slideInFromRight={this.state.slideInFromRight}
            slideOutToLeft={this.state.slideOutToLeft}
            slideOutToRight={this.state.slideOutToRight}
            slideInFromTop={this.state.slideInFromTop}
            slideInFromBottom={this.state.slideInFromBottom}
            slideOutToTop={this.state.slideOutToTop}
            slideOutToBottom={this.state.slideOutToBottom}
          >
            <video className={classes.video} controls src={VideoData[videoIndex].videoUrl} />
            <RouterLink to="#">
              <div className={classes.share}>
                <img src={ButterflyIcon} alt="share" />
                <Typography component="p">SHARE</Typography>
              </div>
            </RouterLink>
          </SlidingImgAndVideo>

          <Grid item container xs={12} md={4} direction="column" justifyContent="flex-start" className={classes.sider}>
            <AnimatedArrowGroup
              data={VideoData}
              itemId={this.state.videoId}
              updateGlobalItemId={this.updateGlobalVideoId}
              categoryIndex={this.props.categoryIndex}
              toggleSlide={this.toggleSlide}
              slideInFromLeft={this.state.slideInFromLeft}
              slideInFromRight={this.state.slideInFromRight}
              slideOutToLeft={this.state.slideOutToLeft}
              slideOutToRight={this.state.slideOutToRight}
            />

            <Divider className={classes.longDivider} />

            <SlidingMediaContent
              slideInFromLeft={this.state.slideInFromLeft}
              slideInFromRight={this.state.slideInFromRight}
              slideOutToLeft={this.state.slideOutToLeft}
              slideOutToRight={this.state.slideOutToRight}
              slideInFromTop={this.state.slideInFromTop}
              slideInFromBottom={this.state.slideInFromBottom}
              slideOutToTop={this.state.slideOutToTop}
              slideOutToBottom={this.state.slideOutToBottom}
            >
              <Typography component="h3" className={classes.videoDetailTitle}>
                {VideoData[videoIndex].title}
              </Typography>
              <Typography component="p" className={classes.videoDetailText}>
                {VideoData[videoIndex].content}
              </Typography>

              {detailInfo}

              <Divider className={classes.shortDivider} />
              <Typography component="h3" className={classes.socialMediaTitle}>
                {VideoData[videoIndex].socialMediaTitle}
              </Typography>
              <Grid item container justifyContent="center" className={classes.socialMediaImg}>
                {socialMediaImg}
              </Grid>
            </SlidingMediaContent>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withRouter(withStyles(VideoDetailStyle)(VideoDetail));
