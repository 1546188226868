import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import VideosMain from './Sections/VideosMain';
import VideoDetail from './VideoDetailPage/VideoDetail';
import VideosMainExpanded from './Sections/VideosMainExpanded';
import styles from './VideoLandingMainPageStyles';

import cardData, {tableTitlesData as tableTitles} from '~/data/cardVideoData';

const PERSON = 'person';
const PLANET = 'planet';

class VideoLandingMain extends Component {
  state = {
    goDetail: false,
    expanded: false,
    videoId: null,
    page: 1,
    category: null,
    categoryIndex: null,
    categoryCode: null,
    selectedCategories: [],
    mode: PERSON,
    noMoreVideos: false
  };

  goDetail = (id, category) => {
    // const currentVideo = cardData.filter((video) => video.id === id);
    // const categoryIndex = currentVideo[0].categoryIndex;
    this.setState({
      detailPage: true,
      videoId: id,
      categoryCode: category
    });
  };

  goExpanded = (e) => {
    this.setState((preState) => ({expanded: !preState.expanded}));
    this.props.history.push(`?expanded=true&mode=${this.state.mode}`);
  };

  setExpended = () => {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const expanded = Boolean(params.get('expanded') && params.get('expanded').toUpperCase() === 'TRUE');
    const mode = Boolean(params.get(PLANET)) ? PLANET : PERSON;
    this.setState((prev) => {
      if (mode !== prev.mode || expanded !== prev.expanded) {
        return {
          mode,
          expanded
        };
      } else {
        return null;
      }
    });
  };

  componentDidMount() {
    // this.props.handleGetCategories();
    // this.props.handleGetInitialVideos(this.state.page);
    // this.setExpended();
  }

  componentDidUpdate() {
    // this.setExpended();
  }

  loadMoreVideos = () => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.scrollingElement.scrollHeight) {
      if (this.state.page < this.props.pagination.lastPage) {
        let nextPage = this.state.page + 1;
        this.setState({
          page: nextPage
        });
        this.props.handleGetMoreVideosByPage(nextPage);
      } else {
        this.setState({
          noMoreVideos: true
        });
      }
    }
  };

  componentWillMount() {
    // this.props.handleGetPagination();
    // window.addEventListener('scroll', this.loadMoreVideos);
  }

  handleTitlesChange = (selectedCategories) => {
    this.setState({selectedCategories});
  };

  render() {
    const {classes, categories, videos} = this.props;
    const data =
      this.state.selectedCategories.length > 0
        ? videos.filter((item) => this.state.selectedCategories.indexOf(item.category) > -1)
        : videos;
    const Content = this.state.expanded ? VideosMainExpanded : VideosMain;

    return (
      <div className={classes.backgroundWrapper}>
        <div className={classes.content}>
          {this.state.goDetail ? (
            <VideoDetail categories={categories} videoId={this.state.videoId} categoryCode={this.state.categoryCode} />
          ) : (
            <Content
              data={data}
              categories={categories}
              goDetail={this.goDetail}
              goExpanded={this.goExpanded}
              // titles={tableTitles}
              selectedCategories={this.state.selectedCategories}
              onTitlesChange={this.handleTitlesChange}
              mode={this.state.mode}
              noMoreVideos={this.state.noMoreVideos}
              handleSwitch={() => {
                const nextMode = this.state.mode === PERSON ? PLANET : PERSON;
                this.setState({mode: nextMode});
                this.props.history.replace(`?${nextMode}=true`);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(VideoLandingMain);
