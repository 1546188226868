import React, {useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import {AppBar, Avatar, Button, Grid, IconButton, Tab, Tabs, TextField, Typography} from '@material-ui/core';
import Image1 from '~/assets/images/content/articles.png';
import Image2 from '~/assets/images/content/videos.png';
import Image3 from '~/assets/images/content/quotes.png';
import Image4 from '~/assets/images/content/initiative_planet_bg.png';
import Image5 from '~/assets/images/content/kimLarkingReel.png';
import Image6 from '~/assets/images/content/kimLarkingreel2.png';

import IconPerson from '~/assets/images/content/main/ic_tab_human.png';
import IconEarth from '~/assets/images/content/main/ic_tab_earth.png';
import IconItemBlue from '~/assets/images/profile/ic-item-personal-profile-myprofile-list-blue.png';
import IconItemGreen from '~/assets/images/profile/ic-item-personal-profile-myprofile-list-green.png';
import IconItemPink from '~/assets/images/profile/ic-item-personal-profile-myprofile-list-pink.png';
import IconItemYellow from '~/assets/images/profile/ic-item-personal-profile-myprofile-list-yellow.png';
import {ArrowBack, Comment, Share, ThumbUpSharp} from '@material-ui/icons';
import TextOverlayImage from '~/components/ui/TextOverlayImage';
import profilePic from '~/assets/images/profile/temp-photos/IMG_4964.jpg';
import DescriptionExpandable from '~/components/ui/DescriptionExpandable/DescriptionExpandable';
import {Link} from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
    // backgroundColor: theme.palette.background.paper
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    gridGap: '4px',
    width: '100%',
    margin: 0,
    boxSizing: 'border-box'
  },
  gridContainer2: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    gridGap: '4px',
    width: '100%',
    margin: 0,
    boxSizing: 'border-box'
  },
  gridItem: {
    textAlign: 'center',
    aspectRatio: '1/1',
    color: theme.palette.text.secondary
  },
  selected: {
    color: 'red !important'
  },
  switch: {
    width: '200px'
  },
  switcherIcon: {
    display: 'inline-block',
    marginRight: '8px',
    width: '15px'
  },

  switchLeft: {
    flex: 1,
    height: '30px',
    lineHeight: '30px',
    borderTopLeftRadius: '15px',
    borderBottomLeftRadius: '15px'
  },
  switchRight: {
    flex: 1,
    height: '30px',
    lineHeight: '30px',
    borderTopRightRadius: '15px',
    borderBottomRightRadius: '15px'
  },
  switchActive: {
    background: '#023B78',
    color: 'white'
  },
  tabWrapper: {
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    '& img': {
      marginBottom: '0px !important'
    }
  },
  tabRoot: {
    minWidth: 0,
    '& > *': {
      minWidth: 0
    }
  },
  commentSection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(0)
  },
  commentInput: {
    flex: 1,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%'
  }
}));

const MyFeed = () => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = React.useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = React.useState(null);
  const [displayExpandedArticle, setDisplayExpandedArticle] = React.useState(null);

  useEffect(() => {
    setSelectedSubCategory(null);
  }, [activeTab, displayExpandedArticle]);
  const tabs = [
    {
      icon: IconItemGreen,
      label: 'Health',
      subCategories: [
        'Health Psychology',
        'Life Force Food',
        'Longevity Activities',
        'Physiological Efficiencies',
        'Natural Remedies',
        'Purity'
      ],
      color: 'rgb(86, 183, 1)',
      content: [
        {img: Image5, description: 'Health Psychology', articlePath: '/content/articles/dark-chocolate-1'},
        {img: Image2, description: 'Life Force Food', articlePath: '/content/articles/life-force-food-2'},
        {img: Image3, description: 'Longevity Activities', articlePath: '/content/articles/longevity-activities-3'},
        {
          img: Image1,
          description: 'Physiological Efficiencies',
          articlePath: '/content/articles/physiological-efficiencies'
        },
        {
          img: Image6,
          description: 'Purity',
          description2: `Dark chocolate is a valuable superfood: With a broad range of antioxidants
        and minerals that help enhance various areas of health: Cell and heart health,
        cognitive function, balancing the immune system, boosting athletic
        performance, reducing stress, enhancing mood, and even helping protect the
        skin from sun damage.
        
        Heavy metals: Unfortunately contains levels of two dangerous heavy metals;
        cadmium and lead.
        
        Cadmium: The cacao plants take up cadmium from the soil. Cadmium can
        cause a variety of adverse health outcomes, including endocrine disruption,
        osteoporosis, and has been classified as a carcinogen.
        
        Lead: Most contamination seems to accumulate during processing,
        manufacturing, and shipping of the beans. Lead exposure can damage the
        nervous system, kidneys, and reproductive organs. It is particularly harmful to
        children, leading to developmental delays, learning difficulties, and
        behavioural problems.
        
        Upper limits: Only consume up to 100 grams per week, or 14 grams per day
        – to maximize its health benefits, while limiting exposure to cadmium and
        lead, as well as sugar.
        Utilise detoxifying foods: Such as broccoli, garlic, green tea, yogurt, ginger,
        grapes, and blueberries, which can help mitigate the effects of heavy metal
        exposure.
        
        Essential minerals: Many studies have shown that a deficiency in essential
        metals such as zinc, calcium, or iron can lead to greater absorption and
        toxicity of Cd and Pb. Hence, a diet rich in such essential minerals may also
        provide protective effects against Cd and Pb toxicity.
        
        Make sure to consult with a health professional to get the appropriate
        advice for you and your circumstances.
        `
        }
      ]
    },
    {
      icon: IconItemBlue,
      label: 'Prosperity',
      subCategories: ['Contextual Intelligence', 'Experiential Learning'],
      color: '#579BFF',
      content: [
        {img: Image4, description: 'Contextual Intelligence'},
        {img: Image3, description: 'Experiential Learning'}
      ]
    },
    {
      icon: IconItemPink,
      label: 'Inspiration',
      subCategories: ['Advanced Parenting', 'New Born Nurture'],
      color: 'rgb(253, 34, 254)',
      content: [
        {img: Image2, description: 'Advanced Parenting'},
        {img: Image1, description: 'New Born Nurture'}
      ]
    },
    {
      icon: IconItemYellow,
      label: 'Joy',
      color: '#FED130',
      subCategories: ['Source', 'Godforce'],
      content: [
        {img: Image3, description: 'Source'},
        {img: Image2, description: 'Godforce'}
      ]
    }
  ];
  useEffect(() => {
    if (displayExpandedArticle == null) {
      return;
    }
    // setTimeout(() => {
    //   document
    //     .getElementById(`${displayExpandedArticle[0]}-${displayExpandedArticle[1]}`)
    //     .scrollIntoView({behavior: 'smooth'});
    // }, 1000);
  }, [displayExpandedArticle]);
  return (
    <>
      <div className={classes.root}>
        {displayExpandedArticle !== null ? (
          <div>
            <Button variant="text" startIcon={<ArrowBack />} onClick={() => setDisplayExpandedArticle(null)}>
              Go Back
            </Button>
            <div className={classes.gridContainer} style={{gridTemplateColumns: 'repeat(1,1fr)'}}>
              {tabs.map((tab, index) => (
                <div key={index} style={{flexDirection: 'column', display: 'flex', gap: 4}}>
                  {tab.content.map((item, index2) => (
                    <div
                      style={{display: 'flex', flexDirection: 'column', background: 'white'}}
                      id={`${index}-${index2}`}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          color: 'white',
                          display: 'flex',
                          flexDirection: 'row',
                          margin: 10,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          gap: 10
                        }}
                      >
                        <img
                          src={profilePic}
                          style={{width: 40, aspectRatio: '1/1', borderRadius: 7, objectFit: 'cover'}}
                        />
                        <div style={{textAlign: 'left'}}>
                          <h1 style={{fontSize: '14px', margin: 0}}>Kim Larking</h1>
                          <span style={{fontSize: '13px'}}>Human advancement architect</span>
                        </div>
                      </div>
                      <TextOverlayImage
                        color={tab.color}
                        key={`${index}-${index2}`}
                        src={item.img}
                        text={item.description}
                      />
                      <div className={classes.commentSection}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                          <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
                            <IconButton aria-label="Like">
                              <ThumbUpSharp />
                            </IconButton>

                            <IconButton aria-label="share">
                              <Comment />
                            </IconButton>

                            <IconButton aria-label="share">
                              <Share />
                            </IconButton>

                            {item.articlePath && (
                              <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <Link to={item.articlePath}>
                                  <Button>Read Full Article on HCN</Button>
                                </Link>
                              </div>
                            )}
                          </div>
                          {item.description2 && (
                            <div style={{boxSizing: 'border-box', paddingLeft: 10, paddingRight: 10, width: '100%'}}>
                              <DescriptionExpandable text={item.description2} />
                            </div>
                          )}
                          <TextField
                            className={classes.commentInput}
                            variant="outlined"
                            size="small"
                            placeholder="Add a comment..."
                          />
                        </div>
                        {/* Additional code for displaying comments can go here */}
                      </div>
                      {/* <img
                      key={`${index}-${index2}`}
                      src={item.img}
                      className={classes.gridItem}
                      style={{width: '100%', objectFit: 'cover'}}
                    />
                    <p>{item.description}</p> */}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <AppBar position="static" color="default">
              <div style={{display: 'flex', justifyContent: 'space-around', padding: '15px'}}>
                {tabs.map((tab, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 4,
                      cursor: 'pointer',
                      color: activeTab === index ? 'rgba(0,0,0,0.5)' : 'inherit'
                    }}
                    onClick={() => setActiveTab(activeTab === index ? null : index)}
                  >
                    <img src={tab.icon} alt="Tab Icon" style={{height: 15}} />
                    <span style={{fontFamily: 'Roboto'}}>{tab.label}</span>
                  </div>
                ))}
              </div>
            </AppBar>
            {activeTab !== null ? (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    overflow: 'auto',
                    marginTop: 0,
                    background: 'rgb(205 205 205)'
                  }}
                >
                  {tabs[activeTab].subCategories.map((cat) => (
                    <Button
                      color="white"
                      style={{whiteSpace: 'nowrap', minWidth: 'min-content', color: 'white !important'}}
                      variant={cat === selectedSubCategory ? 'outlined' : 'text'}
                      onClick={() => {
                        cat === selectedSubCategory ? setSelectedSubCategory(null) : setSelectedSubCategory(cat);
                      }}
                    >
                      <span
                        style={{width: '2px', height: '8.5px', marginRight: '4px', background: tabs[activeTab].color}}
                      />{' '}
                      <span style={{color: 'white'}}>{cat}</span>
                    </Button>
                  ))}
                </div>
                <div className={classes.gridContainer} style={{gridTemplateColumns: 'repeat(2,1fr)'}}>
                  {(selectedSubCategory !== null
                    ? tabs[activeTab].content.filter((c) => c.description === selectedSubCategory)
                    : tabs[activeTab].content
                  ).map((item, index) => (
                    <div
                      className={classes.gridItem}
                      key={index}
                      onClick={() => setDisplayExpandedArticle([activeTab, index])}
                    >
                      <TextOverlayImage color={tabs[activeTab].color} src={item.img} text={item.description} />
                    </div>
                    // <img
                    //   key={index}
                    //   onClick={() => setDisplayExpandedArticle([activeTab, index])}
                    //   src={item.img}
                    //   className={classes.gridItem}
                    //   style={{width: '100%', objectFit: 'cover'}}
                    // />
                  ))}
                </div>
              </div>
            ) : (
              <div className={classes.gridContainer}>
                {tabs.map((tab, index) => (
                  <div key={index} style={{flexDirection: 'column', display: 'flex', gap: 4}}>
                    {tab.content.map((item, index2) => (
                      <div
                        className={classes.gridItem}
                        key={`${index}-${index2}`}
                        o
                        onClick={() => setDisplayExpandedArticle([index, index2])}
                      >
                        <TextOverlayImage color={tab.color} src={item.img} text={item.description} />
                      </div>

                      // <img
                      //   key={`${index}-${index2}`}
                      //   onClick={() => setDisplayExpandedArticle([index, index2])}
                      //   src={item.img}
                      //   className={classes.gridItem}
                      //   style={{width: '100%', objectFit: 'cover'}}
                      // />
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

{
  /* <FeedTemplate
title="MY FEED"
titleIcon={IconTitle}
articleContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci elit, tincidunt quis ligula a, semper molestie tortor. Ut ut libero ullamcorper, tincidunt risus nec."
articleDesc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed orci elit, tincidunt quis ligula a, semper molestie tortor. Ut ut libero ullamcorper, tincidunt risus nec, dapibus felis. Morbi tortor dolor, posuere placerat sapien nec, condimentum varius leo. Pellentesque at fringilla risus, ut ultricies sapien. Nunc rhoncus nunc non purus congue, et dictum risus pulvinar. Donec non ante quam. Pellentesque bibendum, turpis et laoreet mattis, quam libero volutpat augue, a consequat diam nulla eget nisi."
articleImg={ArticleBg}
isArticleVideo
/> */
}
export default MyFeed;
