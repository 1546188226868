import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

function DescriptionExpandable({text}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleReadMore = () => {
    setIsExpanded(true);
  };

  const shortText = text.substring(0, 200) + '...'; // Adjust the character count as needed

  return (
    <div>
      <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
        {isExpanded ? text : shortText}
        {!isExpanded && (
        <span color='grey' onClick={handleReadMore} style={{cursor:"pointer"}}>Read More</span>
      )}
      </Typography>
     
    </div>
  );
}

export default DescriptionExpandable;