import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import {SuspendisBox, MoreCardBox, FlowGrid, ToggleContentTitles} from '~/components/ui';
import CardBox from './ArticleCardBox';
import styles from './ArticlesMainExpandedStyles';

const useStyles = makeStyles(styles);

const ArticlesMainExpanded = (props) => {
  const classes = useStyles(props);
  const {data, titles, selectedTitleIds, onTitlesChange} = props;

  const getCardData = () => {
    return data.concat([{card: 'MORE_ARTICLES', content: 'LOAD MORE ARTICLES'}]);
  };

  return (
    <>
      <Box className={classes.root}>
        <div className={classes.titleContainer}>
          <ToggleContentTitles titles={titles} selectedIds={selectedTitleIds} onChange={onTitlesChange} />
        </div>
        <FlowGrid data={getCardData()} cardHeight="200%" maxColumns={4}>
          {(v) => {
            let card = null;
            if (v.id) {
              card = <CardBox item={v} handleClick={props.goDetail} />;
            } else if (v.card === 'SUSPENDIS_BOX') {
              card = (
                <Box className={classes.suspendisBox}>
                  <SuspendisBox />
                </Box>
              );
            } else if (v.card === 'MORE_ARTICLES') {
              card = <MoreCardBox content={v.content}></MoreCardBox>;
            }
            return card;
          }}
        </FlowGrid>
      </Box>
    </>
  );
};

export default ArticlesMainExpanded;
