import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Grid, Typography} from '@material-ui/core';
import {FlowGrid, ToggleContentTitles} from '~/components/ui';
import CardBox from './ShadableCard';
import styles from './QuotesMainExpandedStyles';

const useStyles = makeStyles(styles);

const QuotesMain = (props) => {
  const classes = useStyles(props);
  const {data, categories, selectedCategories, onTitlesChange, noMoreQuotes} = props;

  return (
    <Box className={classes.root}>
      <div className={classes.titleContainer}>
        <ToggleContentTitles
          categories={categories}
          selectedCategories={selectedCategories}
          onChange={onTitlesChange}
          exclusive
        />
      </div>
      <FlowGrid data={data} maxColumns={4}>
        {(item, completed) => {
          let card = null;
          if (item.id) {
            card = <CardBox item={item} handleClick={props.goDetail} completed={completed} />;
          }
          return card;
        }}
      </FlowGrid>
      {noMoreQuotes ? (
        <Grid container justifyContent="center">
          <Typography component="p" className={classes.noMoreQuotes}>
            No More Quotes
          </Typography>
        </Grid>
      ) : null}
    </Box>
  );
};

export default QuotesMain;
