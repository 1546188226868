import React, { useState } from "react";
import { useQuery } from "react-query";
import { fetchArticles } from "shared/react-query/strapiAPI";
import { makeStyles } from "@material-ui/core/styles";
import { FlowGrid } from "~/components/ui";
import CardBox from "~/pages/content/articles/Sections/ArticleCardBox";
import FetchArticles from "./FetchArticles";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    textAlign: "center",
    aspectRatio: "1/1",
    color: theme.palette.text.secondary,
  },
}));

const DisplayArticles = (props) => {
  let { categories, showAllArticles } = props;
  let filteredArticles = [];

  //* fetch articles based on the selected category or sub category
  filteredArticles = useQuery(["articles", categories], () =>
    fetchArticles(categories, 1)
  );
  //* if there's no data return on selected category/sub category, return
  if (
    (filteredArticles?.data?.data?.length == 0 || filteredArticles.isLoading) &&
    !showAllArticles
  ) {
    return (
      <div style={{ flexDirection: "column", display: "flex", gap: 4 }}></div>
    );
  }
  //* If there's any category/subcategory is selected, display articles wrt selection.
  //* else, display all articles from every category.
  return !showAllArticles ? (
    <div style={{ flexDirection: "column", display: "flex", gap: 4 }}>
      <FlowGrid data={filteredArticles.data.data} cardHeight="200%">
        {(v) => {
          let card = null;
          if (v.id) {
            card = <CardBox item={v} handleClick={props.goDetail} />;
          }
          return card;
        }}
      </FlowGrid>
    </div>
  ) : (
    categories?.map((e, index) => {
      return (
        <FetchArticles
          articles={e}
          goDetail={props.goDetail}
          lastElement={index + 1 === categories.length}
        />
      );
    })
  );
};

export default DisplayArticles;
