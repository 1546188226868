import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Card, CardMedia, CardContent, Typography, Box, Link} from '@material-ui/core';

import {TopButton, SummaryCard} from '~/components/ui';
import {PlayCircleOutlineIcon} from '~/components/ui/icons';

import styles from './VideoCardBoxStyles';

const useStyles = makeStyles(styles);

const VideoCardBox = (props) => {
  const classes = useStyles(props);
  const {id, image, title, content, topbtntext, handleClick, videothumbnail, videothumbnailduration} = props;
  const {className} = props;

  const [showVideoThumbnail, setShowVideoThumbnail] = useState(false);

  useEffect(() => {
    if (!videothumbnail || !videothumbnailduration) {
      return;
    }
    let timerId;
    if (showVideoThumbnail) {
      timerId = setTimeout(() => {
        setShowVideoThumbnail(false);
      }, videothumbnailduration * 1000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [showVideoThumbnail]);

  let videoThumbnail;
  if (!!videothumbnail && !!videothumbnailduration && showVideoThumbnail) {
    videoThumbnail = (
      <div className={classes.videoThumbnailContainer}>
        <img alt={title} src={videothumbnail} />
      </div>
    );
  }

  return (
    <Link
      className={clsx(classes.root, className)}
      href="#"
      onClick={() => handleClick(id)}
      onMouseEnter={() => setShowVideoThumbnail(true)}
      onMouseLeave={() => setShowVideoThumbnail(false)}
    >
      <Card className={classes.container} elevation={0} square={true}>
        <CardMedia className={classes.media} image={image}>
          {videoThumbnail}
          <Box className={classes.playButtonContainer} component="div">
            <PlayCircleOutlineIcon className={classes.playButton} />
          </Box>
          <Typography>
            <TopButton onClick={() => handleClick(id)}>{topbtntext}</TopButton>
          </Typography>
        </CardMedia>

        <CardContent className={classes.contentContainer}>
          <Typography className={classes.title}>{title}</Typography>
          <Box className={classes.contentBox}>
            <SummaryCard className={classes.content} content={content} />
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
};

export default VideoCardBox;
