import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import QuoteDetail from './QuoteDetailPage';
import QuotesMain from './Sections/QuotesMain';
import QuotesMainExpanded from './Sections/QuotesMainExpanded';
import {styles} from './QuotesMainPageStyles';

const PERSON = 'person';
const PLANET = 'planet';

class Quotes extends Component {
  state = {
    detailPage: false,
    quoteId: null,
    page: 1,
    category: null,
    categoryIndex: null,
    categoryCode: null,
    expanded: false,
    width: null,
    height: null,
    selectedCategories: [],
    mode: PERSON,
    noMoreQuotes: false
  };

  setExpended = () => {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const expanded = Boolean(params.get('expanded') && params.get('expanded').toUpperCase() === 'TRUE');
    const mode = Boolean(params.get(PLANET)) ? PLANET : PERSON;
    this.setState((prev) => {
      if (mode !== prev.mode || expanded !== prev.expanded) {
        return {
          mode,
          expanded
        };
      } else {
        return null;
      }
    });
  };

  componentDidMount() {
    // this.props.handleGetCategories();
    // this.props.handleGetInitialQuotes(this.state.page);
    // this.setExpended();
  }

  componentDidUpdate() {
    // this.setExpended();
  }

  loadMoreQuotes = () => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.scrollingElement.scrollHeight) {
      if (this.state.page < this.props.pagination.lastPage) {
        let nextPage = this.state.page + 1;
        this.setState({
          page: nextPage
        });
        this.props.handleGetMoreQuotesByPage(nextPage);
      } else {
        this.setState({
          noMoreQuotes: true
        });
      }
    }
  };

  componentWillMount() {
    // this.props.handleGetPagination();
    // window.addEventListener('scroll', this.loadMoreQuotes);
  }

  goDetail = (id, category) => {
    this.setState({detailPage: true, quoteId: id, categoryCode: category});
    // this.props.history.push(`/detail?id=${id}`);
  };

  goExpanded = (e) => {
    this.setState((preState) => ({expanded: !preState.expanded, detailPage: false}));
    this.props.history.push(`?expanded=true&mode=${this.state.mode}`);
  };

  handleTitlesChange = (selectedCategories) => {
    this.setState({selectedCategories});
  };

  render() {
    const {classes, categories, quotes} = this.props;
    console.log(this.props)
    const data =
      this.state.selectedCategories.length > 0
        ? quotes.filter((item) => this.state.selectedCategories.indexOf(item.category) > -1)
        : quotes;
    const Content = this.state.expanded ? QuotesMainExpanded : QuotesMain;

    return (
      <div className={classes.backgroundWrapper}>
        <div className={classes.content}>
          {this.state.detailPage ? (
            <QuoteDetail categories={categories} quoteId={this.state.quoteId} categoryCode={this.state.categoryCode} />
          ) : (
            <Content
              data={data}
              categories={categories}
              selectedCategories={this.state.selectedCategories}
              onTitlesChange={this.handleTitlesChange}
              goDetail={this.goDetail}
              goExpanded={this.goExpanded}
              mode={this.state.mode}
              noMoreQuotes={this.state.noMoreQuotes}
              handleSwitch={() => {
                console.log('this.state.mode',this.state.mode)
                const nextMode = this.state.mode === PERSON ? PLANET : PERSON;
                this.setState({mode: nextMode});
                this.props.history.replace(`?${nextMode}=true`);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(Quotes);
