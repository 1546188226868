const styles = (theme) => ({
  root: {
    display: 'block',
    height: '100%',
    width: '100%'
  },
  container: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },
  media: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },
  contentContainer: {
    position: 'absolute',
    backgroundColor: 'rgba(23, 23, 23, 0.73)',
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: 5,
    paddingBottom: '5px !important'
  },
  contentBox: {
    width: '100%',
    height: (props) => (props.content ? '4.625rem' : '1.625rem')
  },
  title: {
    color: 'white',
    lineHeight: 2,
    fontSize: 13,
    fontWeight: 700,
    letterSpacing: 3
  },
  content: {
    color: '#fefefe',
    opacity: 0.5,
    fontSize: 11,
    letterSpacing: 1.2
  },
  titleTop: {
    backgroundColor: 'black',
    opacity: 0.8,
    color: 'white',
    padding: '15px 40px',
    lineHeight: 0.1,
    fontSize: 7,
    fontWeight: 200,
    letterSpacing: 3,
    paddingLeft: '30%'
  },
  buttonBox: {
    paddingTop: 20
  },
  videoThumbnailContainer: {
    height: '100%',
    '& > img': {
      height: '100%',
      width: '100%'
    }
  },
  playButtonContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  playButton: {
    display: 'block'
  }
});

export default styles;
