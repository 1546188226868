import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Paper, Button} from '@material-ui/core';
import CKEditor from '@ckeditor/ckeditor5-react';
import InitialContent from './data';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from '@jgrayfibersmith/ckeditor5-build-classic-full-with-base64-upload';
import './ck-content.css';

const useStyles = makeStyles((theme) => ({
  root: {}
}));

const CKEditorSample = () => {
  const classes = useStyles();
  const initialData = InitialContent;
  const [data, setData] = useState(initialData);
  const [content, setContent] = useState('');
  return (
    <Paper className={classes.root}>
      <Box pt={1} pb={6} px={3}>
        <h2>Using CKEditor 5 build in React (Open Source, NOT free)</h2>
        <CKEditor
          editor={ClassicEditor}
          data={initialData}
          onInit={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log('Editor is ready to use!', editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            console.log({event, editor, data});
          }}
          onBlur={(event, editor) => {
            console.log('Blur.', editor);
            setData(editor.getData());
          }}
          onFocus={(event, editor) => {
            console.log('Focus.', editor);
          }}
        />
        <Box mt={1}>
          <Button variant="contained" color="secondary" onClick={() => setContent(data)}>
            Save
          </Button>
        </Box>
        <div className="ck-content" dangerouslySetInnerHTML={{__html: content}}></div>
      </Box>
    </Paper>
  );
};

export default CKEditorSample;
