import React, { useEffect, useState } from "react";
import { AppBar, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useQuery } from "react-query";
import { fetchHCNArticleCategories } from "shared/react-query/strapiAPI";

import DisplayArticles from "~/pages/content/contentData/index";

const Index = (props) => {
  const { mode } = props;
  const [activeTab, setActiveTab] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [subCategory, setSubCategory] = useState();
  const [selectedMode, setSelectedMode] = useState();

  let categories;
  let articlePageCheck = window.location.href.includes("articles");
  const articleBackground = `rgba(255, 255, 255, 0.1)`;

  useEffect(() => {
    let filteredSubCategory = data.data?.data?.filter(
      (e) => e.attributes?.Parent?.data?.id === activeTab
    );
    filteredSubCategory
      ? setSubCategory(filteredSubCategory)
      : setSubCategory(null);
    setSelectedSubCategory(null);
  }, [activeTab]);

  //! whenever the mode changes(person/planet), the sub-categories displayed should also change
  useEffect(() => {
    if (activeTab) {
      categories = data.data?.data?.filter(
        (e) =>
          e.attributes?.Parent?.data?.attributes?.title?.toUpperCase() ===
          mode.toUpperCase()
      );
      let activeTile = categories?.find(
        (e) =>
          e.attributes?.title?.split("-")[0].toUpperCase() ===
          selectedMode.toUpperCase()
      );
      setActiveTab(activeTile?.id);
      setSelectedSubCategory(null);
    }
  }, [mode]);

  //! setSelectedMode to find the active tab name
  const handleTabChange = (tab) => {
    setActiveTab(activeTab === tab.id ? null : tab.id);
    setSelectedMode(tab.attributes.title.split("-")[0]);
  };

  const data = useQuery("HCNArticleCategories", () =>
    fetchHCNArticleCategories()
  );
  categories = data?.data?.data?.filter(
    (e) =>
      e.attributes?.Parent?.data?.attributes?.title?.toUpperCase() ===
      mode.toUpperCase()
  );
  if (!data || data.status === "loading" || !categories) {
    return <></>;
  }
  return (
    <div>
      <AppBar
        position="static"
        style={{
          background: articlePageCheck ? articleBackground : "default",
          border: articlePageCheck ? "1px solid white" : "none",
          color: articlePageCheck ? "white" : "none",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "15px",
          }}
        >
          {categories?.map((tab, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4,
                cursor: "pointer",
                color: activeTab === index ? "rgba(0,0,0,0.5)" : "inherit",
              }}
              onClick={() => handleTabChange(tab)}
            >
              {/* <img src={tab.icon} alt="Tab Icon" style={{height: 15}} /> */}
              <div
                style={{
                  // borderRadius: "10px",
                  // boxShadow: `0px 0px 0px 3px ${tab.attributes.color}, 0px 0px 0px 2px ${tab.attributes.color}`,
                  // width: "20px",
                  // height: "20px",
                  background: `${tab.attributes.color}`,
                  border: "2px solid white",
                  borderRadius: "30px",
                  height: "12px",
                  width: "12px",
                  position: "relative",
                  boxShadow: `0 0 0 1px ${tab.attributes.color}`,
                }}
              ></div>

              <span
                id="categpory"
                style={{
                  fontFamily: "Roboto",
                  textDecoration: activeTab === tab.id ? "underline" : "",
                  color: articlePageCheck ? "white" : "black",
                }}
              >
                {tab.attributes.title.split("-")[0]}
              </span>
            </div>
          ))}
        </div>
      </AppBar>
      {activeTab !== null ? (
        <div>
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              overflow: "auto",
              marginTop: 0,
              // background: "rgb(205 205 205)",
            }}
          >
            {subCategory?.map((cat, index) => (
              <Button
                key={index}
                color="white"
                style={{
                  whiteSpace: "nowrap",
                  minWidth: "min-content",
                  color: "white !important",
                  background:
                    cat?.id === selectedSubCategory
                      ? "rgba(214,212,212,0.5)"
                      : "none",
                }}
                onClick={() => {
                  cat?.attributes?.title === selectedSubCategory
                    ? setSelectedSubCategory(null)
                    : setSelectedSubCategory(cat?.id);
                }}
              >
                <span
                  style={{
                    width: "2px",
                    height: "8.5px",
                    marginRight: "4px",
                    borderRight: `2px solid ${cat?.attributes?.color}`,
                  }}
                />{" "}
                <span style={{ color: "white" }}>{cat?.attributes?.title}</span>
              </Button>
            ))}
          </div>
          <DisplayArticles
            categories={selectedSubCategory ? selectedSubCategory : activeTab}
            showAllArticles={false}
            goDetail={props.goDetail}
          />
        </div>
      ) : (
        <>
          <DisplayArticles
            categories={categories}
            showAllArticles={true}
            goDetail={props.goDetail}
          />
        </>
      )}
    </div>
  );
};

export default Index;
