import React, {Component} from 'react';
import {withRouter, Link as RouterLink} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {withStyles, Grid, Divider} from '@material-ui/core';

import SlidingImgAndVideo from '~/components/ui/SlidingImgAndVideo/SlidingImgAndVideo';
import AnimatedArrowGroup from '~/components/ui/AnimatedArrowGroup/AnimatedArrowGroup';
import SlidingMediaContent from '~/components/ui/SlidingMediaContent/SlidingMediaContent';

import ButterflyIcon from '~/assets/images/icons/share_btn.png';

import QuoteDetailStyle from './QuoteDetailStyle';

class QuoteDetail extends Component {
  state = {
    slideInFromLeft: false,
    slideInFromRight: false,
    slideOutToLeft: false,
    slideOutToRight: false,
    slideInFromTop: false,
    slideInFromBottom: false,
    slideOutToTop: false,
    slideOutToBottom: false,

    quoteId: this.props.quoteId,
    categoryCode: this.props.categoryCode
  };

  toggleSlide = (key) => {
    this.setState({
      [key]: true
    });
    setTimeout(() => {
      this.setState({
        [key]: false
      });
    }, 500);
  };

  updateGlobalQuoteId = (id) => {
    this.setState({
      quoteId: id
    });
    this.props.handleGetQuoteById(id);
  };

  componentDidMount() {
    this.props.handleGetQuoteById(this.state.quoteId);
    this.props.handleGetQuotesByCategory(this.state.categoryCode);
  }

  render() {
    const {classes, categories, quotes, quote} = this.props;
    const categoryIndex = categories.findIndex((cate) => cate.code === quote.category);
    console.log(this.props.quote.id);
    // if (quote.media !== undefined) {
    //   console.log(quote.media['1001'].map((item, i) => item.id));
    // }

    let detailInfo =
      quote.details === undefined
        ? ''
        : quote.details.map((item) => (
            <section key={item.id}>
              <Divider className={classes.shortDivider} />
              <Typography component="h3" className={classes.quoteSubTitle}>
                {item.title}
              </Typography>
              <Typography component="p" className={classes.quoteDetailText}>
                {item.content}
              </Typography>
            </section>
          ));

    return (
      <div className={classes.container}>
        <Grid container alignItems="flex-start">
          <SlidingImgAndVideo
            slideInFromLeft={this.state.slideInFromLeft}
            slideInFromRight={this.state.slideInFromRight}
            slideOutToLeft={this.state.slideOutToLeft}
            slideOutToRight={this.state.slideOutToRight}
            slideInFromTop={this.state.slideInFromTop}
            slideInFromBottom={this.state.slideInFromBottom}
            slideOutToTop={this.state.slideOutToTop}
            slideOutToBottom={this.state.slideOutToBottom}
          >
            <img className={classes.quotesDetailImage} src={quote.cover_image} alt="Quote" />
            <RouterLink to="#">
              <div className={classes.share}>
                <img src={ButterflyIcon} alt="share" />
                <Typography component="p">SHARE</Typography>
              </div>
            </RouterLink>
          </SlidingImgAndVideo>

          <Grid item container xs={12} md={4} direction="column" justifyContent="flex-start" className={classes.sider}>
            <AnimatedArrowGroup
              itemId={this.state.quoteId}
              updateGlobalItemId={this.updateGlobalQuoteId}
              handleGetQuotesByCategory={this.props.handleGetQuotesByCategory}
              categoryCode={this.state.categoryCode}
              categories={categories}
              categoryIndex={categoryIndex}
              items={quotes}
              toggleSlide={this.toggleSlide}
              slideInFromLeft={this.state.slideInFromLeft}
              slideInFromRight={this.state.slideInFromRight}
              slideOutToLeft={this.state.slideOutToLeft}
              slideOutToRight={this.state.slideOutToRight}
            />

            <Divider className={classes.longDivider} />

            <SlidingMediaContent
              slideInFromLeft={this.state.slideInFromLeft}
              slideInFromRight={this.state.slideInFromRight}
              slideOutToLeft={this.state.slideOutToLeft}
              slideOutToRight={this.state.slideOutToRight}
              slideInFromTop={this.state.slideInFromTop}
              slideInFromBottom={this.state.slideInFromBottom}
              slideOutToTop={this.state.slideOutToTop}
              slideOutToBottom={this.state.slideOutToBottom}
            >
              <Typography component="h3" className={classes.quoteContentTitle}>
                {quote.title}
              </Typography>
              <Typography component="p" className={classes.quoteContentText}>
                {quote.content}
              </Typography>

              <Typography component="h4" className={classes.authorTitle}>
                AUTHOR
              </Typography>
              <Typography component="p" className={classes.authorDetail}>
                {quote.author_name}
              </Typography>

              {detailInfo}

              <Divider className={classes.shortDivider} />
              <Typography component="h3" className={classes.quoteSubTitle}>
                {quote.socialMediaTitle}
              </Typography>
              <Grid item container justifyContent="center" className={classes.socialMediaImg}>
                {/* {socialMediaImg} */}
              </Grid>
            </SlidingMediaContent>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withRouter(withStyles(QuoteDetailStyle)(QuoteDetail));
