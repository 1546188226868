import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {CardContent, Box} from '@material-ui/core';
import {BottomButton, SummaryCard} from '~/components/ui';
import VideoCardBox from './VideoCardBox';
import styles from './VideoExpandedCardBoxStyles';

const useStyles = makeStyles(styles);

const VideoExpandedCardBox = (props) => {
  const classes = useStyles();
  const {handleClick} = props;
  const {id, content, bottombtntext} = props;

  return (
    <Box className={classes.root}>
      <Box className={classes.cardContainer}>
        <VideoCardBox handleClick={handleClick} className={classes.card} {...props} {...{content: null}} />
      </Box>
      <Box className={classes.moreContainer}>
        <CardContent className={classes.more}>
          <SummaryCard content={content} />
        </CardContent>
        <BottomButton className={classes.bottomButton} onClick={() => handleClick(id)}>
          {bottombtntext}
        </BottomButton>
      </Box>
    </Box>
  );
};

export default VideoExpandedCardBox;
