import axios from 'axios';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_STRAPI_API_URL}/`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`
  }
});


export const fetchArticle = async (slug) => {
  try {
    const response = await apiClient.get(`api/articles?filters[slug][$eq]=${slug}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching article:', error);
    throw error;
  }
};


export const fetchAdvancementExerciseCategory = async (slug, token) => {
  try {
    const response = await apiClient.get(`api/advancement-exercise-categories/${slug}?populate[video][fields][0]=title&populate[video][fields][1]=description&populate[video][populate][video][fields][0]=url`, {
      headers: {
        "x-shopify-access-token": token
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching exercise:', error);
    throw error;
  }
};

export const fetchVideo = async (segment) => {
  try {
    const response = await apiClient.get(`${segment}`, {
      responseType: 'blob' // Ensure response type is 'blob'
    });
    const videoBlob = response.data;
    const buf = await videoBlob.arrayBuffer();
    const videoUrl = URL.createObjectURL(new Blob([videoBlob], { type: "video/mp4" }));
    return videoUrl;
  } catch (error) {
    console.error('Error fetching exercise video:', error);
    throw error;
  }
};

export const fetchVisionaireProfile = async (slug, token) => {
  try {
    const response = await apiClient.get(`api/visionaires/${slug}`, {
      headers: {
        "x-shopify-access-token": token ?? ""
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching profile:', error);
    throw error;
  }
};


export const fetchProfile = async (token) => {
  try {
    const response = await apiClient.get(`api/profile`, {
      headers: {
        "x-shopify-access-token": token ?? ""
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching profile:', error);
    throw error;
  }
};


export const fetchArticles = async (articleCategoryId, pageNumber, pageSize = 9) => {
  try {
    const response = await apiClient.get(`api/articles?filters[$or][0][hcn_article_category][id][$eq]=${articleCategoryId}&filters[$or][1][hcn_article_category][Children][id][$eq]=${articleCategoryId}&filters[$or][2][hcn_article_category][Parent][id][$eq]=${articleCategoryId}&populate[carousel][fields][0]=url&populate[hcn_article_category][fields][0]=id&populate[hcn_article_category][fields][1]=title&populate[hcn_article_category][fields][2]=parent&populate[hcn_article_category][fields][3]=children&populate[hcn_article_category][populate][Parent][fields][0]=id&populate[hcn_article_category][populate][Parent][fields][1]=title&populate[hcn_article_category][populate][Children][fields][0]=id&populate[hcn_article_category][populate][Children][fields][1]=title&pagination[pageSize]=${pageSize}&pagination[page]=${pageNumber}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching articles:', error);
    throw error;
  }
};


export const fetchHCNArticleCategories = async () => {
  try {
    const response = await apiClient.get(`api/hcn-article-categories?fields[0]=id&fields[1]=title&populate[Parent][fields][0]=id&populate[Parent][fields][1]=title&populate[Children][fields][0]=id&populate[Children][fields][1]=title&fields[3]=color&pagination[pageSize]=100`);
    return response.data;
  } catch (error) {
    console.error('Error fetching HCNArticleCategories:', error);
    throw error;
  }
};
