import React from "react";
import { useQuery } from "react-query";
import { fetchArticles } from "shared/react-query/strapiAPI";
import { FormArticleData } from "./FormArticleData";

let dataArr = [];

const FetchArticles = ({ articles, goDetail, lastElement }) => {
  let data = useQuery(["articles", articles.id], () =>
    fetchArticles(articles.id, 1)
  );

  const checkIfIdExists = (element) => {
    element.forEach((e) => {
      let indexOfElement = dataArr?.findIndex((ele) => ele.id === e.id);
      if (indexOfElement == -1) {
        dataArr.push(e);
      }
    });
  };

  if (data?.data?.data?.length > 0) {
    checkIfIdExists(data?.data?.data);
  }
  if (lastElement) {
    let finalData = dataArr;
    dataArr = [];
    return <FormArticleData goDetail={goDetail} dataArr={finalData} />;
  } else {
    return <></>;
  }
};

export default FetchArticles;
