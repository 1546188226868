const styles = (theme) => ({
  root: {
    width: '100%'
  },
  cardContainer: {
    position: 'relative',
    paddingTop: '100%'
  },
  card: {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  moreContainer: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    paddingTop: '50%',
    backgroundColor: '#ffffff',
    textAlign: 'center'
  },
  more: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingBottom: 65,
    overflow: 'hidden',
    textAlign: 'left',
    color: '#a5a5a5',
    letterSpacing: '1.3px'
  },
  moreContent: {
    height: '100%',
    width: '100%',
    overflow: 'hidden'
  },
  bottomButton: {
    color: '#b2650c',
    borderColor: '#b2650c',
    fontSize: 11,
    position: 'absolute',
    left: 'calc(50% - 60px)',
    bottom: 25,
  }
});

export default styles;
