import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import { ExpandBar, FlowGrid, ToggleContentTitles } from "~/components/ui";
// import CardBox from "./ShadableCard";
import styles from "./QuotesMainStyles";
import MyFeed from "~/components/layout/MyFeed/index";

const useStyles = makeStyles(styles);

const QuotesMain = (props) => {
  const classes = useStyles(props);
  const {
    data,
    categories,
    selectedCategories,
    onTitlesChange,
    mode,
    noMoreQuotes,
    handleSwitch,
  } = props;
  return (
    <Container className={classes.root}>
      <ExpandBar
        nav="Quotes"
        subNav={mode}
        goExpanded={props.goExpanded}
        switcher={{
          items: ["Person", "Planet"],
          checkedIndex: mode === "planet" ? 1 : 0,
          onChange: handleSwitch,
        }}
      />
      <ToggleContentTitles
        categories={categories}
        selectedCategories={selectedCategories}
        onChange={onTitlesChange}
      />
      <MyFeed data={data} mode={mode} />

      {noMoreQuotes ? (
        <Grid container justifyContent="center">
          <Typography component="p" className={classes.noMoreQuotes}>
            No More Quotes
          </Typography>
        </Grid>
      ) : null}
    </Container>
  );
};

export default QuotesMain;
