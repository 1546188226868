import React, { useEffect } from 'react';
import {withStyles} from '@material-ui/core';

import ContentTab from './ContentTab';

import BusinessProfile from './business/profile/Profile';
import BusinessNewsFeed from './business/basic/NewsFeed';
import BioContent from './business/bio/Content';
import StarContent from './business/star/Content';
import Location from './business/location/Location';
import MyProfile from './personal/profile/MyProfile';

import MyFeed from './personal/profile/MyFeed';
import MyNetwork from './personal/profile/MyNetwork';
import EditProfile from './personal/edit/EditProfile';
import MyMedia from './personal/profile/MyMedia';
import MyMessenger from './personal/profile/Messenger';

import Health from './personal/Health';
import Prosperity from './personal/Prosperity';
import Inspiration from './personal/Inspiration';
import Joy from './personal/Joy';

import FavQuotes from './common/fav/Quotes';
import FavArticles from './common/fav/Articles';
import FavVideos from './common/fav/Videos';
import FavGuides from './common/fav/Guides';


const styles = (theme) => ({});

function renderBusinessContent(data, tabIndex) {
  const tab = data.tabs && data.tabs[tabIndex];
  switch (data.id) {
    case 0:
      if (tabIndex === 0) {
        return <BusinessProfile />;
      } else if (tabIndex === 1) {
        return <BusinessNewsFeed />;
      } else if (tabIndex === 2) {
        return <MyNetwork />;
      }
      break;
    case 1:
      return <BioContent tab={tab}></BioContent>;
    case 2:
      if (tab.content) {
        return <StarContent tab={tab}></StarContent>;
      }
      break;
    case 3:
      break;
    case 4:
      if (tabIndex === 0) {
        return <FavQuotes />;
      } else if (tabIndex === 1) {
        return <FavArticles />;
      } else if (tabIndex === 2) {
        return <FavVideos />;
      } else if (tabIndex === 3) {
        return <FavGuides />;
      }
      break;
    default:
      break;
  }
  return null;
}

function renderPersonTabContent(data, tabIndex) {
  // const tab = data.tabs && data.tabs[tabIndex];
  switch (data.id) {
    case 0:
      if (tabIndex === 0) {
        return <h1>Account details go here</h1>;
      }
      else if (tabIndex === 1) {
        return <MyProfile />;
      } else if (tabIndex === 2) {
        return <MyFeed />;
      } else if (tabIndex === 3) {
        return <MyNetwork />;
      } else if (tabIndex === 4) {
        return <MyMedia />;
      } else if (tabIndex === 5) {
        return <MyMessenger />;
      }

      break;
    case 1:
      if (tabIndex === 0) {
        return <Health />;
      }
      break;
    case 2:
      if (tabIndex === 0) {
        return <Prosperity />;
      }
      break;
    case 3:
      if (tabIndex === 0) {
        return <Inspiration />;
      }
      break;
    case 4:
      if (tabIndex === 0) {
        return <Joy />;
      }
      break;
    case 5:
      if (tabIndex === 0) {
        return <EditProfile />;
      }
      break;
    case 6:
      if (tabIndex === 0) {
        return <FavQuotes />;
      } else if (tabIndex === 1) {
        return <FavArticles />;
      } else if (tabIndex === 2) {
        return <FavVideos />;
      } else if (tabIndex === 3) {
        return <FavGuides />;
      }
      break;
    default:
      break;
  }
  return null;
}

function RightContent(props) {
  const {data, selectTabIndex, onTabClick, isPersonal, isLoggedinUserProfile} = props;
  if (data.tabs) {
    return (
      <div>
        <ContentTab tabs={data.tabs} isLoggedinUserProfile={isLoggedinUserProfile} selectIndex={selectTabIndex} onTabClick={(i)=>{onTabClick(null); 
          setTimeout(()=>{
            onTabClick(i);
          },1)
         } } />
        <div>
          {isPersonal ? renderPersonTabContent(data, selectTabIndex) : renderBusinessContent(data, selectTabIndex)}
        </div>
      </div>
    );
  } else {
    if (!isPersonal) {
      return <Location />;
    }
  }
  return null;
}

export default withStyles(styles)(RightContent);
