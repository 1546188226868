const styles = (theme) => ({
  carousel: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '600px',

    [theme.breakpoints.up('xl')]: {
      height: '800px'
    },
    [theme.breakpoints.only('xs')]: {
      height: '380px'
    },
    overflow: 'hidden'
  },
  carousel_slide: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    zIndex: '1',
    display: 'flex',
    flexDirection: 'column',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%'
    }
  },

  carousel_arrows: {
    position: 'absolute',
    width: '100%',
    zIndex: '1',
    color: 'rgba(255,255,255, 0.5)',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& svg': {
      cursor: 'pointer',
      fontSize: '5rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '3rem'
      },
      '&:hover': {
        transform: 'scale(1.2)',
        transition: 'all 0.2s ease'
      }
    }
  },

  carousel_history: {
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: '100%',
    zIndex: '20',
    boxSizing: 'border-box',
    padding: '1% 2% 5% 2%',
    backgroundColor: 'rgba(0, 0, 0, 0.64)',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '1%'
    }
  },
  carousel_desc: {
    width: "100%",
    boxSizing: "border-box",
    position: "absolute",
    padding: '1% 2% 5% 2%',
    backgroundColor: 'rgba(0, 0, 0, 0.64)',
    bottom: "12px",
    opacity: '0.9',
    color: 'white',
    fontFamily: 'Calibri',
    fontSize: '16px',
    lineHeight: '1.63',
    letterSpacing: '2.4px',
    [theme.breakpoints.down('sm')]: {
      padding: '1% 5% 5% 5%',
    }
  },

  carousel_history_ul: {
    listStyle: 'none',
    padding: '0 2% 0 4%',
    margin: '0 auto 0 6%',
    display: 'flex',
    WebkitBoxPack: 'start',
    justifyContent: 'flex-start',
    height: '11px'
  },
  carousel_history_ul_li: {
    width: '10%',
    boxSizing: 'border-box',
    height: 'inherit',
    position: 'relative',
    marginRight: '13%',
    '&:last-of-type': {
      marginRight: '0',
      marginLeft: '0',
      '& div': {
        marginLeft: '-3em'
      }
    },
    '&:first-of-type': {
      marginLeft: '-7px'
    }
  },

  carousel_history_ul_li_div: {
    width: '91px',
    height: '22px',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '4',
    textAlign: 'left',
    marginLeft: '-2em',
    letterSpacing: '1px',
    color: '#d2a75f',
    cursor: 'pointer',
    [theme.breakpoints.only('sm')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },

  carousel_history_ul_li_button: {
    border: '0px solid transparent',
    borderRadius: '7px',
    outline: 'none',
    position: 'absolute',
    padding: '0',
    top: '0',
    left: '0',
    backgroundColor: '#d2a75f',
    width: '14px',
    height: '14px',
    cursor: 'pointer',
    WebkitTransition: 'background-color 300ms ease',
    transition: 'background-color 300ms ease'
  },
  carousel_history_ul_li_button_active: {
    backgroundColor: 'white',
    border: '3px solid #d2a75f',
    borderRadius: '7px'
  },
  carousel_history_hr: {
    border: '1px solid #d2a75f',
    marginTop: '-5px',
    width: '80%'
  },

  enter_next: {
    WebkitTransform: 'translateX(100%)',
    transform: 'translateX(100%)'
  },
  enter_prev: {
    WebkitTransform: 'translateX(-100%)',
    transform: 'translateX(-100%)',
    WebkitTransition: '-webkit-transform 500ms linear',
    transition: 'transform 500ms linear, -webkit-transform 500ms linear'
  },
  enter_active: {
    WebkitTransform: 'translateX(0)',
    transform: 'translateX(0)',
    WebkitTransition: '-webkit-transform 500ms linear',
    transition: 'transform 500ms linear, -webkit-transform 500ms linear'
  },
  leave: {
    WebkitTransform: 'translateX(0)',
    transform: 'translateX(0)'
  },
  leave_active_next: {
    WebkitTransform: 'translateX(-100%)',
    transform: 'translateX(-100%)',
    WebkitTransition: '-webkit-transform 500ms linear',
    transition: 'transform 500ms linear, -webkit-transform 500ms linear'
  },
  leave_active_prev: {
    WebkitTransform: 'translateX(100%)',
    transform: 'translateX(100%)',
    WebkitTransition: '-webkit-transform 500ms linear',
    transition: 'transform 500ms linear, -webkit-transform 500ms linear'
  }
});

export default styles;
