import React from "react";
import {
  makeStyles,
  Container,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  ShareVideoBox,
  ExpandBar,
  MoreCardBox,
  FlowGrid,
  ToggleContentTitles,
} from "~/components/ui";
// import shareBtn from "~/assets/images/organize/share_btn.png";
// import CardBox from "./VideoCardBox";
import styles from "./VideosMainStyles";

import MyFeed from "~/components/layout/MyFeed/index";

const useStyles = makeStyles(styles);

const VideosMain = (props) => {
  const classes = useStyles(props);
  const {
    data,
    categories,
    selectedCategories,
    onTitlesChange,
    mode,
    noMoreVideos,
    handleSwitch,
  } = props;
  console.log("nomorevideos", noMoreVideos);
  const handleClick = (id) => {
    props.goDetail(id);
    // props.getIndex(id);
    // console.log('this is:', param);
  };

  const getCardData = () => {
    return data.concat([{ card: "MORE_VIDEOS", content: "LOAD MORE VIDEOS" }]);
  };

  return (
    <>
      <Container className={classes.root}>
        <ExpandBar
          nav="Videos"
          subNav={mode}
          goExpanded={props.goExpanded}
          switcher={{
            items: ["Person", "Planet"],
            checkedIndex: mode === "planet" ? 1 : 0,
            onChange: handleSwitch,
          }}
        />
        <ToggleContentTitles
          categories={categories}
          selectedCategories={selectedCategories}
          onChange={onTitlesChange}
        />
        <MyFeed data={data} mode={mode} />
        {noMoreVideos ? (
          <Grid container justifyContent="center">
            <Typography component="p" className={classes.noMoreVideos}>
              No More Videos
            </Typography>
          </Grid>
        ) : null}
      </Container>
    </>
  );
};

export default VideosMain;
