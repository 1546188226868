const styles = (theme) => {
  return {
    root: (props) => ({}),
    cardContainer: {
      width: '100%',
      position: 'relative',
      paddingTop: '150%',
      overflow: 'hidden'
    },
    card: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      margin: 'auto'
    },
    titleContainer: {
      backgroundColor: '#434343',
      textAlign: 'center'
    },
    noMoreQuotes: {
      color: '#ffffff',
      marginTop: 15,
      marginBottom: 15
    }
  };
};

export default styles;
