import videoImage from '../assets/images/content/videos.png';
import videoThumbnail from '../assets/images/content/video-sample-thumnail.gif';
import video1 from '../assets/videos/SampleVideo_1280x720_1mb.mp4';
import video2 from '../assets/videos/sea-video.mp4';
import video3 from '../assets/videos/portraitSampleVideo.mp4';
import socialImageURL from '../assets/images/content/youtubeImg.png';

// import grayCube from '../assets/images/icons/grayCube.png';
import greenCube from '../assets/images/icons/greenCube.png';
import blueCube from '../assets/images/icons/blueCube.png';
import pinkCube from '../assets/images/icons/pinkCube.png';
import yellowCube from '../assets/images/icons/yellowCube.png';

// const title = 'VIDEO NAME A';
const titleTop = 'BIO INTERDEPENDENCE';
const content = `Video Content In this world world Law, Religion, Media, Health, Family and Community. All of which work in unison to enhance our abundance of ecological, physical, social, financial and spiritual well-being. Video Content In this world world Law, Religion, Media, Health, Family and Community. All of which work in unison to enhance our abundance of ecological, physical, social, financial and spiritual well-being. financial and spiritual well-being. Video Content In this world world Law, Religion, Media, Health, Family and Community. All of which work in unison to enhance our abundance of ecological, physical, social, financial and spiritual well-being.`;
const directorText = 'Free videos: https://mixkit.co/';
const topBtnText = 'Eco Process';
const bottomBtnText = 'Watch Now';
const playCircleOutlineIconClickText = '>';
const image = videoImage;

// const title2 = 'VIDEO NAME B';
const titleTop2 = 'BIO INTERDEPENDENCE';
const content2 = `VIDEO CONTENT In this world world Law, Religion, Media, Health, Family and Community. All of which work in unison to enhance our abundance of ecological, physical, social, financial and spiritual well-being. financial and spiritual well-being. Video Content In this world world Law, Religion, Media, Health, Family and Community. All of which work in unison to enhance our abundance of ecological, physical, social, financial and spiritual well-being.`;
const directorText2 = 'Free videos: https://abcde.co.nz/';
const topBtnText2 = 'Eco Process';
const bottomBtnText2 = 'Watch Now';
const playCircleOutlineIconClickText2 = '>';
const image2 = videoImage;
const socialMediaTitle = 'YOUTUBE';

const cardVideoData = [
  {
    id: 1,
    title: 'VIDEO NAME 1',
    titleTop: titleTop,
    image: image,
    categoryIndex: 0,
    videoUrl: video1,
    playcircleoutlineiconclicktext: playCircleOutlineIconClickText,
    content: content,
    topbtntext: topBtnText,
    bottombtntext: bottomBtnText,
    videothumbnail: videoThumbnail,
    videothumbnailduration: 5,
    categoryId: 1,
    detailInfo: [
      {
        id: 0,
        title: 'DIRECTOR',
        description: directorText
      },
      {
        id: 1,
        title: 'PHOTOGRAPHOR',
        description: directorText2
      },
      {
        id: 2,
        title: 'EDITOR',
        description: directorText
      }
    ],
    socialMediaTitle: socialMediaTitle,
    socialImageURLs: [
      {
        id: 0,
        url: socialImageURL
      },
      {
        id: 1,
        url: socialImageURL
      },
      {
        id: 2,
        url: socialImageURL
      },
      {
        id: 3,
        url: socialImageURL
      },
      {
        id: 4,
        url: socialImageURL
      }
    ]
  },
  {},
  {
    id: 2,
    title: 'VIDEO NAME 2',
    titleTop: titleTop2,
    image: image2,
    categoryIndex: 2,
    videoUrl: video3,
    playcircleoutlineiconclicktext: playCircleOutlineIconClickText2,
    content: content2,
    topbtntext: topBtnText2,
    bottombtntext: bottomBtnText2,
    categoryId: 3,
    detailInfo: [
      {
        id: 0,
        title: 'DIRECTOR',
        description: directorText
      },
      {
        id: 1,
        title: 'PHOTOGRAPHOR',
        description: directorText2
      },
      {
        id: 2,
        title: 'EDITOR',
        description: directorText
      }
    ],
    socialMediaTitle: socialMediaTitle,
    socialImageURLs: [
      {
        id: 0,
        url: socialImageURL
      },
      {
        id: 1,
        url: socialImageURL
      },
      {
        id: 2,
        url: socialImageURL
      },
      {
        id: 3,
        url: socialImageURL
      },
      {
        id: 4,
        url: socialImageURL
      }
    ]
  },
  {
    id: 3,
    title: 'VIDEO NAME 3',
    titleTop: titleTop,
    image: image,
    categoryIndex: 3,
    videoUrl: video2,
    playcircleoutlineiconclicktext: playCircleOutlineIconClickText,
    content: content,
    topbtntext: topBtnText,
    bottombtntext: bottomBtnText,
    categoryId: 4,
    detailInfo: [
      {
        id: 0,
        title: 'DIRECTOR',
        description: directorText
      },
      {
        id: 1,
        title: 'PHOTOGRAPHOR',
        description: directorText2
      },
      {
        id: 2,
        title: 'EDITOR',
        description: directorText
      }
    ],
    socialMediaTitle: socialMediaTitle,
    socialImageURLs: [
      {
        id: 0,
        url: socialImageURL
      },
      {
        id: 1,
        url: socialImageURL
      },
      {
        id: 2,
        url: socialImageURL
      },
      {
        id: 3,
        url: socialImageURL
      },
      {
        id: 4,
        url: socialImageURL
      }
    ]
  },
  {
    id: 4,
    title: 'VIDEO NAME 4',
    titleTop: titleTop,
    image: image,
    categoryIndex: 0,
    videoUrl: video2,
    playcircleoutlineiconclicktext: playCircleOutlineIconClickText,
    content: content,
    topbtntext: topBtnText,
    bottombtntext: bottomBtnText,
    categoryId: 2,
    detailInfo: [
      {
        id: 0,
        title: 'DIRECTOR',
        description: directorText
      },
      {
        id: 1,
        title: 'PHOTOGRAPHOR',
        description: directorText2
      },
      {
        id: 2,
        title: 'EDITOR',
        description: directorText
      }
    ],
    socialMediaTitle: socialMediaTitle,
    socialImageURLs: [
      {
        id: 0,
        url: socialImageURL
      },
      {
        id: 1,
        url: socialImageURL
      },
      {
        id: 2,
        url: socialImageURL
      },
      {
        id: 3,
        url: socialImageURL
      },
      {
        id: 4,
        url: socialImageURL
      }
    ]
  },
  {
    id: 5,
    title: 'VIDEO NAME 5',
    titleTop: titleTop,
    image: image,
    categoryIndex: 1,
    videoUrl: video1,
    playcircleoutlineiconclicktext: playCircleOutlineIconClickText,
    content: content,
    topbtntext: topBtnText,
    bottombtntext: bottomBtnText,
    categoryId: 2,
    detailInfo: [
      {
        id: 0,
        title: 'DIRECTOR',
        description: directorText
      },
      {
        id: 1,
        title: 'PHOTOGRAPHOR',
        description: directorText2
      },
      {
        id: 2,
        title: 'EDITOR',
        description: directorText
      }
    ],
    socialMediaTitle: socialMediaTitle,
    socialImageURLs: [
      {
        id: 0,
        url: socialImageURL
      },
      {
        id: 1,
        url: socialImageURL
      },
      {
        id: 2,
        url: socialImageURL
      },
      {
        id: 3,
        url: socialImageURL
      },
      {
        id: 4,
        url: socialImageURL
      }
    ]
  },
  {},
  {
    id: 6,
    title: 'VIDEO NAME 6',
    titleTop: titleTop,
    image: image,
    categoryIndex: 3,
    videoUrl: video2,
    playcircleoutlineiconclicktext: playCircleOutlineIconClickText,
    content: content,
    topbtntext: topBtnText,
    bottombtntext: bottomBtnText,
    categoryId: 1,
    detailInfo: [
      {
        id: 0,
        title: 'DIRECTOR',
        description: directorText
      },
      {
        id: 1,
        title: 'PHOTOGRAPHOR',
        description: directorText2
      },
      {
        id: 2,
        title: 'EDITOR',
        description: directorText
      }
    ],
    socialMediaTitle: socialMediaTitle,
    socialImageURLs: [
      {
        id: 0,
        url: socialImageURL
      },
      {
        id: 1,
        url: socialImageURL
      },
      {
        id: 2,
        url: socialImageURL
      },
      {
        id: 3,
        url: socialImageURL
      },
      {
        id: 4,
        url: socialImageURL
      }
    ]
  },
  {card: 'SHARE_OUR_VIDEOS'},
  {
    id: 7,
    title: 'VIDEO NAME 7',
    titleTop: titleTop,
    image: image,
    categoryIndex: 1,
    videoUrl: video2,
    playcircleoutlineiconclicktext: playCircleOutlineIconClickText,
    content: content,
    topbtntext: topBtnText,
    bottombtntext: bottomBtnText,
    categoryId: 1,
    detailInfo: [
      {
        id: 0,
        title: 'DIRECTOR',
        description: directorText
      },
      {
        id: 1,
        title: 'PHOTOGRAPHOR',
        description: directorText2
      },
      {
        id: 2,
        title: 'EDITOR',
        description: directorText
      }
    ],
    socialMediaTitle: socialMediaTitle,
    socialImageURLs: [
      {
        id: 0,
        url: socialImageURL
      },
      {
        id: 1,
        url: socialImageURL
      },
      {
        id: 2,
        url: socialImageURL
      },
      {
        id: 3,
        url: socialImageURL
      },
      {
        id: 4,
        url: socialImageURL
      }
    ]
  },
  {
    id: 8,
    title: 'VIDEO NAME 8',
    titleTop: titleTop,
    image: image,
    categoryIndex: 2,
    videoUrl: video1,
    playcircleoutlineiconclicktext: playCircleOutlineIconClickText,
    content: content,
    topbtntext: topBtnText,
    bottombtntext: bottomBtnText,
    categoryId: 1,
    detailInfo: [
      {
        id: 0,
        title: 'DIRECTOR',
        description: directorText
      },
      {
        id: 1,
        title: 'PHOTOGRAPHOR',
        description: directorText2
      },
      {
        id: 2,
        title: 'EDITOR',
        description: directorText
      }
    ],
    socialMediaTitle: socialMediaTitle,
    socialImageURLs: [
      {
        id: 0,
        url: socialImageURL
      },
      {
        id: 1,
        url: socialImageURL
      },
      {
        id: 2,
        url: socialImageURL
      },
      {
        id: 3,
        url: socialImageURL
      },
      {
        id: 4,
        url: socialImageURL
      }
    ]
  },
  {
    id: 9,
    title: 'VIDEO NAME 9',
    titleTop: titleTop,
    image: image,
    categoryIndex: 3,
    videoUrl: video1,
    playcircleoutlineiconclicktext: playCircleOutlineIconClickText,
    content: content,
    topbtntext: topBtnText,
    bottombtntext: bottomBtnText,
    categoryId: 3,
    detailInfo: [
      {
        id: 0,
        title: 'DIRECTOR',
        description: directorText
      },
      {
        id: 1,
        title: 'PHOTOGRAPHOR',
        description: directorText2
      },
      {
        id: 2,
        title: 'EDITOR',
        description: directorText
      }
    ],
    socialMediaTitle: socialMediaTitle,
    socialImageURLs: [
      {
        id: 0,
        url: socialImageURL
      },
      {
        id: 1,
        url: socialImageURL
      },
      {
        id: 2,
        url: socialImageURL
      },
      {
        id: 3,
        url: socialImageURL
      },
      {
        id: 4,
        url: socialImageURL
      }
    ]
  },
  {
    id: 10,
    title: 'VIDEO NAME 10',
    titleTop: titleTop2,
    image: image2,
    categoryIndex: 0,
    videoUrl: video2,
    playcircleoutlineiconclicktext: playCircleOutlineIconClickText2,
    content: content2,
    topbtntext: topBtnText2,
    bottombtntext: bottomBtnText2,
    categoryId: 1,
    detailInfo: [
      {
        id: 0,
        title: 'DIRECTOR',
        description: directorText
      },
      {
        id: 1,
        title: 'PHOTOGRAPHOR',
        description: directorText2
      },
      {
        id: 2,
        title: 'EDITOR',
        description: directorText
      }
    ],
    socialMediaTitle: socialMediaTitle,
    socialImageURLs: [
      {
        id: 0,
        url: socialImageURL
      },
      {
        id: 1,
        url: socialImageURL
      },
      {
        id: 2,
        url: socialImageURL
      },
      {
        id: 3,
        url: socialImageURL
      },
      {
        id: 4,
        url: socialImageURL
      }
    ]
  },
  {
    id: 11,
    title: 'VIDEO NAME 11',
    titleTop: titleTop,
    image: image,
    categoryIndex: 1,
    videoUrl: video1,
    playcircleoutlineiconclicktext: playCircleOutlineIconClickText,
    content: content,
    topbtntext: topBtnText,
    bottombtntext: bottomBtnText,
    categoryId: 4,
    detailInfo: [
      {
        id: 0,
        title: 'DIRECTOR',
        description: directorText
      },
      {
        id: 1,
        title: 'PHOTOGRAPHOR',
        description: directorText2
      },
      {
        id: 2,
        title: 'EDITOR',
        description: directorText
      }
    ],
    socialMediaTitle: socialMediaTitle,
    socialImageURLs: [
      {
        id: 0,
        url: socialImageURL
      },
      {
        id: 1,
        url: socialImageURL
      },
      {
        id: 2,
        url: socialImageURL
      },
      {
        id: 3,
        url: socialImageURL
      },
      {
        id: 4,
        url: socialImageURL
      }
    ]
  },
  {
    id: 12,
    title: 'VIDEO NAME 12',
    titleTop: titleTop,
    image: image,
    categoryIndex: 2,
    videoUrl: video2,
    playcircleoutlineiconclicktext: playCircleOutlineIconClickText,
    content: content,
    topbtntext: topBtnText,
    bottombtntext: bottomBtnText,
    categoryId: 4,
    detailInfo: [
      {
        id: 0,
        title: 'DIRECTOR',
        description: directorText
      },
      {
        id: 1,
        title: 'PHOTOGRAPHOR',
        description: directorText2
      },
      {
        id: 2,
        title: 'EDITOR',
        description: directorText
      }
    ],
    socialMediaTitle: socialMediaTitle,
    socialImageURLs: [
      {
        id: 0,
        url: socialImageURL
      },
      {
        id: 1,
        url: socialImageURL
      },
      {
        id: 2,
        url: socialImageURL
      },
      {
        id: 3,
        url: socialImageURL
      },
      {
        id: 4,
        url: socialImageURL
      }
    ]
  },
  {
    id: 13,
    title: 'VIDEO NAME 13',
    titleTop: titleTop,
    image: image,
    categoryIndex: 3,
    videoUrl: video1,
    playcircleoutlineiconclicktext: playCircleOutlineIconClickText,
    content: content,
    topbtntext: topBtnText,
    bottombtntext: bottomBtnText,
    categoryId: 2,
    detailInfo: [
      {
        id: 0,
        title: 'DIRECTOR',
        description: directorText
      },
      {
        id: 1,
        title: 'PHOTOGRAPHOR',
        description: directorText2
      },
      {
        id: 2,
        title: 'EDITOR',
        description: directorText
      }
    ],
    socialMediaTitle: socialMediaTitle,
    socialImageURLs: [
      {
        id: 0,
        url: socialImageURL
      },
      {
        id: 1,
        url: socialImageURL
      },
      {
        id: 2,
        url: socialImageURL
      },
      {
        id: 3,
        url: socialImageURL
      },
      {
        id: 4,
        url: socialImageURL
      }
    ]
  },
  {
    id: 14,
    title: 'VIDEO NAME 14',
    titleTop: titleTop,
    image: image,
    categoryIndex: 0,
    videoUrl: video2,
    playcircleoutlineiconclicktext: playCircleOutlineIconClickText,
    content: content,
    topbtntext: topBtnText,
    bottombtntext: bottomBtnText,
    categoryId: 1,
    detailInfo: [
      {
        id: 0,
        title: 'DIRECTOR',
        description: directorText
      },
      {
        id: 1,
        title: 'PHOTOGRAPHOR',
        description: directorText2
      },
      {
        id: 2,
        title: 'EDITOR',
        description: directorText
      }
    ],
    socialMediaTitle: socialMediaTitle,
    socialImageURLs: [
      {
        id: 0,
        url: socialImageURL
      },
      {
        id: 1,
        url: socialImageURL
      },
      {
        id: 2,
        url: socialImageURL
      },
      {
        id: 3,
        url: socialImageURL
      },
      {
        id: 4,
        url: socialImageURL
      }
    ]
  },
  {
    id: 15,
    title: 'VIDEO NAME 15',
    titleTop: titleTop,
    image: image,
    categoryIndex: 1,
    videoUrl: video1,
    playcircleoutlineiconclicktext: playCircleOutlineIconClickText,
    content: content,
    topbtntext: topBtnText,
    bottombtntext: bottomBtnText,
    categoryId: 1,
    detailInfo: [
      {
        id: 0,
        title: 'DIRECTOR',
        description: directorText
      },
      {
        id: 1,
        title: 'PHOTOGRAPHOR',
        description: directorText2
      },
      {
        id: 2,
        title: 'EDITOR',
        description: directorText
      }
    ],
    socialMediaTitle: socialMediaTitle,
    socialImageURLs: [
      {
        id: 0,
        url: socialImageURL
      },
      {
        id: 1,
        url: socialImageURL
      },
      {
        id: 2,
        url: socialImageURL
      },
      {
        id: 3,
        url: socialImageURL
      },
      {
        id: 4,
        url: socialImageURL
      }
    ]
  },
  {
    id: 16,
    title: 'VIDEO NAME 16',
    titleTop: titleTop,
    image: image,
    categoryIndex: 2,
    videoUrl: video1,
    playcircleoutlineiconclicktext: playCircleOutlineIconClickText,
    content: content,
    topbtntext: topBtnText,
    bottombtntext: bottomBtnText,
    categoryId: 4,
    detailInfo: [
      {
        id: 0,
        title: 'DIRECTOR',
        description: directorText
      },
      {
        id: 1,
        title: 'PHOTOGRAPHOR',
        description: directorText2
      },
      {
        id: 2,
        title: 'EDITOR',
        description: directorText
      }
    ],
    socialMediaTitle: socialMediaTitle,
    socialImageURLs: [
      {
        id: 0,
        url: socialImageURL
      },
      {
        id: 1,
        url: socialImageURL
      },
      {
        id: 2,
        url: socialImageURL
      },
      {
        id: 3,
        url: socialImageURL
      },
      {
        id: 4,
        url: socialImageURL
      }
    ]
  }
];

export const cubeIcons = [greenCube, blueCube, pinkCube, yellowCube];

// export const tableTitles = [
//   'VITALITY & LONGEVITY',
//   'WISDOM & SUCCESS',
//   'EMPOWERMENT & SIGNIFICANCE',
//   'CLARITY & BEAUTY'
// ];

export const tableTitlesData = [
  {id: 1, text: 'VITALITY & LONGEVITY'},
  {id: 2, text: 'WISDOM & SUCCESS'},
  {id: 3, text: 'EMPOWERMENT & SIGNIFICANCE'},
  {id: 4, text: 'CLARITY & BEAUTY'}
];

export default cardVideoData;
