const styles = (theme) => {
  return {
    root: (props) => ({}),
    titleContainer: {
      backgroundColor: '#434343',
      textAlign: 'center'
    },
    shareVideo: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2%',
      textAlign: 'center'
    }
  };
};

export default styles;
