import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import {MoreCardBox, FlowGrid, ShareVideoBox, ToggleContentTitles} from '~/components/ui';
import CardBox from './VideoExpandedCardBox';
import shareBtn from '~/assets/images/organize/share_btn.png';
import styles from './VideosMainExpandedStyles';

const useStyles = makeStyles(styles);

const VideosMainExpanded = (props) => {
  const classes = useStyles(props);
  const {data, titles, selectedTitleIds, onTitlesChange} = props;

  const handleClick = (id) => {
    props.goDetail(id);
    // console.log('this is:', param);
  };

  const getCardData = () => {
    return data
      .reduce((prev, cur) => {
        if (cur.id || cur.card) prev.push(cur);
        return prev;
      }, [])
      .concat([{card: 'MORE_VIDEOS', content: 'LOAD MORE VIDEOS'}]);
  };

  return (
    <>
      <Box className={classes.root}>
        <div className={classes.titleContainer}>
          <ToggleContentTitles titles={titles} selectedIds={selectedTitleIds} onChange={onTitlesChange} />
        </div>
        <FlowGrid data={getCardData()} cardHeight="150%" maxColumns={4}>
          {(v) => {
            let card = null;
            if (v.id) {
              card = (
                <CardBox
                  id={v.id}
                  image={v.image}
                  title={v.title}
                  titleTop={v.titleTop}
                  content={v.content}
                  topbtntext={v.topbtntext}
                  bottombtntext={v.bottombtntext}
                  playcircleoutlineiconclicktext={v.playcircleoutlineiconclicktext}
                  videothumbnail={v.videothumbnail}
                  videothumbnailduration={v.videothumbnailduration}
                  handleClick={handleClick}
                  categoryIndex={v.categoryIndex}
                />
              );
            } else if (v.card === 'SHARE_OUR_VIDEOS') {
              card = (
                <Box className={classes.shareVideo}>
                  <ShareVideoBox shareBtn={shareBtn} />
                </Box>
              );
            } else if (v.card === 'MORE_VIDEOS') {
              card = <MoreCardBox content={v.content}></MoreCardBox>;
            }
            return card;
          }}
        </FlowGrid>
      </Box>
    </>
  );
};

export default VideosMainExpanded;
